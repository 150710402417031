<template>
  <div v-if="loaded">
    <h4 class="page-title">사원 정보 수정</h4>

    <edit-form />
  </div>
</template>
<script>
  import EditForm from "@/components/user/manage/employee/EditForm";
  import {dispatch} from "@/core/dispatcher";
  import SelectEmployeeJob from "@/core/jobs/user/SelectEmployeeJob";

  export default {
    data() {
      return {
        loaded: false
      };
    },
    components: {
      EditForm
    },
    mounted() {
      dispatch(new SelectEmployeeJob(parseInt(this.$route.params.id))).then(() => {
        this.loaded = true;
      });
    }
  }
</script>
