import {PaginateData} from "@/core/types";

export default {
  namespaced: true,
  state: {
    announcements: [],
    total: 0,
    per_page: 0,
    page: 0,
    selectedAnnouncement: null
  },
  mutations: {
    selectAnnouncement(state: any, data: any) {
      state.selectedAnnouncement = {
        ...data
      };
    },
    setAnnouncements(state: any, data: PaginateData) {
      state.announcements = [
        ...data.data
      ];
      state.total = data.total;
      state.per_page = data.per_page;
      state.page = data.page;
    }
  }
}
