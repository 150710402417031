import {DispatchContext, Job} from "@/core/types";
import {updateEmployeeInfo, UpdateEmployeeInfoData} from "@/core/api/employee";
import {dispatch} from "@/core/dispatcher";
import LoadEmployeesJob from "@/core/jobs/user/LoadEmployeesJob";

export default class UpdateEmployeeInfoJob implements Job {
  private readonly id: number;
  private readonly data: UpdateEmployeeInfoData;

  constructor(id: number, data: UpdateEmployeeInfoData) {
    this.id = id;
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await updateEmployeeInfo(this.id, this.data);

    return dispatch(new LoadEmployeesJob(response.data.data.user_id, context.store.state.user.employee.page));
  }
}
