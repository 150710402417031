<template>
  <div>
    <side-bar
      :menus="menus"
    />
    <div class="page">
      <router-view
      />
    </div>
  </div>
</template>
<script>
  import SideBar from '@/components/layouts/SideBar';

  export default {
    data() {
      return {
        menus: [
          {
            icon :'fa fa-user',
            label : '사용자 관리',
            routeName: 'user.index',
            super: false
          },
          {
            icon: 'fa fa-user-check',
            label: '사용자 인증 관리',
            routeName: 'user.certificate.index',
            super: true
          },
          {
            icon: 'fa fa-user-tie',
            label: '입/퇴사 사원 관리',
            routeName: 'user.employee.index',
            super: false
          },
          {
            icon: 'fa fa-bell',
            label: '푸시 전송',
            routeName: 'user.notification.index',
            super: false
          }
        ]
      }
    },
    components: {
      SideBar
    }
  }
</script>
