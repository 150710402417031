<template>
  <div>
    <h4 class="page-title">사용자 인증 관리</h4>

    <certificate-user-list />
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import LoadUncertificatedUserListJob from "@/core/jobs/user/LoadUncertificatedUserListJob";

  import CertificateUserList from '@/components/user/certificate/Index';

  export default {
    mounted() {
      dispatch(new LoadUncertificatedUserListJob(0));
    },
    components: {
      CertificateUserList
    }
  }
</script>
