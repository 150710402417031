<template>
  <div>
      <div class="row">
      <div class="col-md-6">
        <property-form
          icon="fa fa-plus-square"
          title="급여항목"
          type="wage"
          :page="wage.page"
          :per_page="wage.per_page"
          :total="wage.total"
          :properties="wage.properties"
          v-model="wages"
        />
      </div>
      <div class="col-md-6">
        <property-form
          icon="fa fa-minus-square"
          title="공제항목"
          type="exclude"
          :page="exclude.page"
          :per_page="exclude.per_page"
          :total="exclude.total"
          :properties="exclude.properties"
          v-model="excludes"
        />
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="save">
        저장
      </button>
    </div>
  </div>
</template>
<script>
  import PropertyForm from './PropertyForm';
  import {mapState} from 'vuex';
  import {dispatch} from "@/core/dispatcher";
  import SyncUserAndBasePropertyJob from "@/core/jobs/base-property/SyncUserAndBasePropertyJob";

  export default {
    data() {
      return {
        wages: [],
        excludes: []
      };
    },
    computed: {
      ...mapState('user', {
        user: state => state.selectedUser
      }),
      ...mapState('baseProperty', {
        wage: state => state.wage,
        exclude: state => state.exclude
      })
    },
    methods: {
      save() {
        dispatch(new SyncUserAndBasePropertyJob(
          parseInt(this.$route.params.id),
          [
            ...this.wages,
            ...this.excludes
          ]
        ));
      }
    },
    components: {
      PropertyForm
    },
    mounted() {
      this.wages = [
        ...this.user.wage_properties.map(wage => wage.id),
      ];

      this.excludes = [
        ...this.user.exclude_properties.map(exclude => exclude.id)
      ];
    }
  }
</script>
