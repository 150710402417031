import Page from '@/views/admin/my-page/Page.vue';

import AccountIndexView from '@/views/admin/my-page/account/Index.vue';

export default [
  {
    path: 'my-page',
    component: Page,
    children: [
      {
        path: 'account',
        component: AccountIndexView,
        name: 'my-page.account.index'
      }
    ]
  }
]
