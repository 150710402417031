<template>
  <div>
    <app-header />
    <router-view />
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />
    <image-selector />
  </div>
</template>
<script>
  import AppHeader from '@/components/layouts/Header.vue';
  import Loading from 'vue-loading-overlay';
  import ImageSelector from "@/components/modal/ImageSelector";

  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState({
        isLoading: state => state.isLoading
      })
    },
    components: {
      AppHeader,
      Loading,
      ImageSelector
    }
  }
</script>
