import {PaginateData} from "@/core/types";

export default {
  namespaced: true,
  state: {
    open: false,
    callback: null,
    images: [],
    total: 0,
    per_page: 0,
    page: 0
  },
  mutations: {
    setImages(state: any, data: PaginateData) {
      state.images = [
        ...data.data
      ];
      state.total = data.total;
      state.per_page = data.per_page;
      state.page = data.page;
    },
    openModal(state: any, callback: any) {
      state.open = true;
      state.callback = callback;
    },
    imageSelected(state: any, url: string) {
      state.open = false;
      state.callback(url);
    },
    closeModal(state: any) {
      state.open = false;
    }
  }
}
