import * as authApi from '@/core/api/auth';
// @ts-ignore
import jscookie from 'js-cookie';
import {DispatchContext, Job} from "@/core/types";

export default class LoginJob implements Job {
  private readonly userId: string;
  private readonly password: string;

  constructor(userId: string, password: string) {
    this.userId = userId;
    this.password = password;
  }

  async run(context: DispatchContext) {
    await context.authManager.login(this.userId, this.password);

    const userResponse = await authApi.admin();
    context.store.commit('auth/setAdmin', userResponse.data.data);
  }
}
