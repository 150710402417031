<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-search" />
        검색
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <label for="user_name">회사명</label>
            <input type="text" id="user_name" class="form-control" v-model="search.user_name">
          </div>
          <div class="col-md-4 form-inline">
            <label for="from">전송일</label>
            <br />
            <v-date-picker
              id="from"
              v-model="search.from"
            />
            ~
            <v-date-picker
              v-model="search.to"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="text-right mb-5">
      <button type="button" class="btn btn-primary" @click="movePage(currentPage)">
        검색
      </button>
    </div>
    <br />
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-list"></i> 로그 목록
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <colgroup>
            <col width="80" />
            <col width="200" />
            <col width="*" />
            <col width="200" />
          </colgroup>
          <thead>
          <tr>
            <th></th>
            <th>사용자명</th>
            <th>알림 메시지</th>
            <th>전송일</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(log, index) in logs">
            <tr>
              <td align="center">{{ total - (per_page * (page - 1)) - index }}</td>
              <td>{{ log.user_name }}</td>
              <td v-html="log.text.replace(/\n/g, '<br>')"></td>
              <td>{{ log.created_at }}</td>
            </tr>
          </template>
          </tbody>
        </table>

        <pagination
          :page="currentPage"
          :per-page="per_page"
          :total="total"
          @move-page="movePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import LoadLogsJob from "@/core/jobs/alarm-log/LoadLogsJob";
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        currentPage: 1,
        search: {
          user_name: '',
          from: '',
          to: ''
        }
      }
    },
    computed: {
      ...mapState('alarmLog', {
        logs: state => state.logs,
        total: state => state.total,
        per_page: state => state.per_page,
        page: state => state.page
      })
    },
    methods: {
      movePage(page) {
        this.currentPage = page;

        dispatch(new LoadLogsJob(page, {
          user_name: this.search.user_name,
          from: this.search.from,
          to: this.search.to
        }));
      }
    },
    created() {
      this.movePage(1);
    }
  }
</script>
