<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user-tie"></i>직원 목록
      </div>
      <div class="card-body">
        <div class="list-group">
          <template v-for="(employee, index) in employees">
            <button
              type="button"
              class="list-group-item"
              @click="select(employee.id)"
              :class="{active: employee.id === userIndex}"
            >
              {{ employee.name }}
              <template v-if="employee.payslip_values.filter(v => v.type === 'wage' && v.value !== 0).length > 0">(입력완료)</template>
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState, mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapState('payslip', {
        userIndex: state => state.userIndex,
        type: state => state.payslipType
      }),
      ...mapGetters('payslip', {
        employees: 'employees'
      })
    },
    methods: {
      select(id) {
        this.$emit('select', id);
      }
    }
  }
</script>
