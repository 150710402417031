import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import auth from './auth';
import admin from './admin';

import Page404 from '@/views/errors/404.vue';

const routes = [
  ...auth,
  ...admin,
  {
    path: '/*',
    component: Page404
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router
