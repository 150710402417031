<template>
  <div class="card">
    <div class="card-heading">
      <i class="fa fa-file-upload"></i> 엑셀 파일 업로드
    </div>
    <div class="card-body">
      <div class="form-group">
        <label>
          <input type="radio" value="douzone" name="type" v-model="type"> 더존 양식
        </label>
        &nbsp;&nbsp;&nbsp;
        <label>
          <input type="radio" value="semusarang" name="type" v-model="type"> 세무사랑 양식
        </label>
      </div>
      <div class="form-group">
        <v-input-file
          id="excel-file"
          v-model="file"
        />
      </div>
      <div class="text-right">
        <button type="button" class="btn btn-primary btn-sm" @click="upload">
          업로드
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import UploadExcelJob from "@/core/jobs/excel/UploadExcelJob";

  export default {
    data() {
      return {
        type: 'douzone',
        file: null
      };
    },
    methods: {
      upload() {
        if (this.file === null) {
          alert('파일을 선택해주세요.');
          return;
        }

        this.$emit('upload', {
          file: this.file,
          type: this.type
        });

        this.file = null;
      }
    }
  }
</script>
