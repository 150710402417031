import {DispatchContext, Job} from "@/core/types";
import {admin} from "@/core/api/auth";

export default class GetAdminFromSessionTokenJob implements Job {
  async run(context: DispatchContext): Promise<any> {
    const response = await admin();

    context.store.commit('auth/setAdmin', response.data.data);
  }
}
