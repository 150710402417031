import UserPage from '@/views/admin/user/Page.vue';
import UserIndex from '@/views/admin/user/manage/Index.vue';
import UserEdit from "@/views/admin/user/manage/Edit.vue";

import UserCertificate from '@/views/admin/user/certificate/Index.vue';
import UserEmployeeList from '@/views/admin/user/manage/EmployeeList.vue';
import UserEmployeeEdit from '@/views/admin/user/manage/EditEmployee.vue';
import UserEmployeeCreate from '@/views/admin/user/manage/CreateEmployee.vue';

import EmployeeIndex from '@/views/admin/user/employee/Index.vue';
import EmployeeList from "@/views/admin/user/employee/EmployeeList.vue";

import NotificationIndex from '@/views/admin/user/notification/Index.vue';

export default [
  {
    path: 'user',
    component: UserPage,
    children: [
      {
        path: 'notification',
        component: NotificationIndex,
        name: 'user.notification.index'
      },
      {
        path: 'manage',
        component : UserIndex,
        name: 'user.index'
      },
      {
        path: 'manage/:id',
        component: UserEdit,
        name: 'user.edit'
      },
      {
        path: 'manage/:id/employee',
        component: UserEmployeeList,
        name: 'user.edit.employee.index'
      },
      {
        path: 'manage/employee/:id/edit',
        component: UserEmployeeEdit,
        name: 'user.edit.employee.edit'
      },
      {
        path: 'manage/:id/employee/create',
        component: UserEmployeeCreate,
        name: 'user.edit.employee.create'
      },
      {
        path: 'certificate',
        component: UserCertificate,
        name: 'user.certificate.index'
      },
      {
        path: 'employee',
        component: EmployeeIndex,
        name: 'user.employee.index'
      },
      {
        path: 'employee/:id',
        component: EmployeeList,
        name: 'user.employee.show'
      }
    ]
  }
];
