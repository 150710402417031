<template>
  <table class="table table-bordered table-hover">
    <thead>
    <tr>
      <th width="20%">항목명</th>
      <th width="40%">금액</th>
      <th width="40%"></th>
    </tr>
    </thead>
    <tbody>
    <template v-for="(property, index) in value">
      <tr>
        <td>
          <template v-if="property.name === '기본급' && employee.employee_type === '사업소득자'">
            지급액
          </template>
          <template v-else>
            {{ property.name }}
          </template>
        </td>
        <td>
          <input
            type="number"
            class="form-control"
            @input="update($event, index)"
            :value="property.value"
          >
        </td>
        <td align="right">
          {{ property.value.format() }}
        </td>
      </tr>
    </template>
    <tr v-if="showTotal !== 'false'">
      <th>합계</th>
      <td colspan="2" align="right">
        {{ value.reduce((a, b) => a + parseInt(b.value), 0).format() }}
      </td>
    </tr>
    <tr v-if="finalPayment !== undefined">
      <th>차인지급액</th>
      <td colspan="2" align="right">
        {{ finalPayment.format() }}
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>
  import {mapState} from 'vuex';

  export default {
    props: ['value', 'employee', 'finalPayment', 'showTotal'],
    computed: {
      ...mapState('payslip', {
        payslip: state => state.payslip
      })
    },
    methods: {
      update(ev, index) {
        const properties = [
          ...this.value
        ];

        properties[index] = {
          ...properties[index],
          value: ev.target.value
        };

        this.$emit('input', properties);
      }
    }
  }
</script>
