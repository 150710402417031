import {DispatchContext, Job} from "@/core/types";

import * as userApi from '@/core/api/user';
import {UpdateUserData} from "@/core/api/user";

export default class UpdateUserJob implements Job {
  private readonly data: any;
  private readonly userId: number;

  constructor(userId: number, data: UpdateUserData) {
    this.userId = userId;
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await userApi.confirm(this.userId, this.data);

    context.store.commit('user/updateUser', {
      id: this.userId,
      data: response.data.data
    });
  }
}
