import bootstrap from './bootstrap';
import './sass/app.scss';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import VInput from '@/components/global/VInput.vue';
import VInputFile from '@/components/global/VInputFile.vue';
import Pagination from '@/components/global/Pagination.vue';
import VDatePicker from '@/components/global/VDatePicker.vue';
import VSelect from '@/components/global/VSelect.vue';

Vue.component('v-input', VInput);
Vue.component('pagination', Pagination);
Vue.component('v-input-file', VInputFile);
Vue.component('v-date-picker', VDatePicker);
Vue.component('v-select', VSelect);

// @ts-ignore
Number.prototype.format = function(){
  if(this==0) return 0;

  let reg = /(^[+-]?\d+)(\d{3})/;
  let n = (this + '');

  while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');

  return n;
};

// @ts-ignore
String.prototype.format = function(){
  // @ts-ignore
  var num = parseFloat(this);
  if( isNaN(num) ) return "0";

  // @ts-ignore
  return num.format();
};

bootstrap().then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});
