import * as http from './http';

export interface AccountData {
  userid: string,
  email: string,
  phone_number: string,
  password?: string
}

export function updateAccount(id: number, data: AccountData) {
  return http.put(`/api/v1/admin/account/${id}`, data);
}

export function getAccounts() {
  return http.get('/api/v1/admin/account');
}

export function createAccount(data: AccountData) {
  return http.post('/api/v1/admin/account', data);
}

export function destroyAccount(idList: Array<number>) {
  return http.destroy(`/api/v1/admin/account?id[]=${idList.join('&id[]=')}`);
}
