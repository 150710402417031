import {Admin} from "@/core/types/model";

export default {
  namespaced: true,
  state: {
    accounts: []
  },
  mutations: {
    setAccounts(state: any, accounts: Array<Admin>) {
      state.accounts = [
        ...accounts
      ];
    }
  }
}
