<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-list"></i> 사원 목록
      </div>
      <div class="card-body">
        <table class="table table-bordered employee-list">
          <thead>
          <tr>
            <th width="70"></th>
            <th>사원코드</th>
            <th>이름</th>
            <th>생년월일</th>
            <th>직급</th>
            <th>부서</th>
            <th>입사일</th>
            <th>퇴사일</th>
            <th>근무타입</th>
            <th>급여타입</th>
            <th>처리종류</th>
            <th width="120"></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(employee, index) in employees">
            <tr :class="{out: employee.in_completed === true}">
              <td align="center">{{ employees.length - index }}</td>
              <td>{{ employee.code }}</td>
              <td>{{ employee.name }}</td>
              <td>{{ employee.birthday }}</td>
              <td>{{ employee.position }}</td>
              <td>{{ employee.department }}</td>
              <td>{{ employee.in_date }}</td>
              <td>{{ employee.out_date }}</td>
              <td>{{ employee.employee_type }}</td>
              <td>{{ employee.pay_type }}</td>
              <td>
                {{ employee.in_completed === false ? '입사' : '퇴사' }}
              </td>
              <td>
                <button type="button" class="btn btn-default btn-sm" @click="openModal(employee)">
                  확인
                </button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>

    <div class="text-right">
      <button type="button" class="btn btn-default btn-sm" @click="$router.back()">
        뒤로
      </button>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" ref="modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" v-if="selectedEmployee !== null">{{ selectedEmployee.name }}</h4>
          </div>
          <div class="modal-body">
            <employee-form
              v-if="selectedEmployee !== null && selectedEmployee.in_completed === false"
              :employee="selectedEmployee"
              v-model="files"
            />
            <left-employee-form
              v-if="selectedEmployee !== null && selectedEmployee.in_completed === true"
              :employee="selectedEmployee"
              v-model="leave_files"
            />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save">확인</button>
            <button type="button" class="btn btn-default" data-dismiss="modal">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import LoadNotCompletedEmployeesJob from "@/core/jobs/employee/LoadNotCompletedEmployeesJob";
  import {mapState, mapGetters} from 'vuex';
  import EmployeeForm from "./EmployeeForm";
  import LeftEmployeeForm from "./LeftEmployeeForm";
  import UpdateEmployeeJob from "@/core/jobs/employee/UpdateEmployeeJob";

  export default {
    data() {
      return {
        selectedEmployee: null,
        files: {
          regist: null
        },
        leave_files: {
          out: null,
          out2: null,
          income_tax: 0,
          jumin_tax: 0,
          etc_exclude: 0,
          final_payment: 0,
          severance_pay: 0
        }
      };
    },
    computed: {
      ...mapState('employeeManage', {
        employees: state => state.employee.employees,
        total: state => state.employee.total,
        per_page: state => state.employee.per_page,
        page: state => state.employee.page
      })
    },
    watch: {
      'leave_files.severance_pay': function(v) {
        this.leave_files.final_payment =
          v -
          this.leave_files.income_tax -
          this.leave_files.jumin_tax -
          this.leave_files.etc_exclude;
      },
      'leave_files.income_tax': function(v) {
        this.leave_files.final_payment =
          this.leave_files.severance_pay -
          v -
          this.leave_files.jumin_tax -
          this.leave_files.etc_exclude;
      },
      'leave_files.jumin_tax': function(v) {
        this.leave_files.final_payment =
          this.leave_files.severance_pay -
          this.leave_files.income_tax -
          v -
          this.leave_files.etc_exclude;
      },
      'leave_files.etc_exclude': function(v) {
        this.leave_files.final_payment =
          this.leave_files.severance_pay -
          this.leave_files.income_tax -
          this.leave_files.jumin_tax -
          v;
      }
    },
    methods: {
      save() {
        if (this.selectedEmployee.in_completed === false) {
          dispatch(new UpdateEmployeeJob(this.selectedEmployee.id, {
            regist_file: this.files.regist,
            out_file: this.files.out
          })).then(() => {
            $(this.$refs.modal).modal('hide');

            this.files = {
              regist: null
            };

            dispatch(new LoadNotCompletedEmployeesJob(parseInt(this.$route.params.id), this.page));

            alert('입력 완료');
          });
        } else {
          dispatch(new UpdateEmployeeJob(this.selectedEmployee.id, {
            out_file: this.leave_files.out,
            out_file2: this.leave_files.out2,
            jumin_tax: this.leave_files.jumin_tax,
            income_tax: this.leave_files.income_tax,
            etc_exclude: this.leave_files.etc_exclude,
            final_payment: this.leave_files.final_payment,
            severance_pay: this.leave_files.severance_pay
          })).then(() => {
            $(this.$refs.modal).modal('hide');

            this.leave_files = {
              out: null,
              out2: null,
              income_tax: 0,
              jumin_tax: 0,
              etc_exclude: 0,
              final_payment: 0,
              severance_pay: 0
            };

            dispatch(new LoadNotCompletedEmployeesJob(parseInt(this.$route.params.id), this.page));

            alert('입력 완료');
          });
        }
      },
      openModal(employee) {
        this.selectedEmployee = employee;
        this.leave_files.severance_pay = employee.severance_pay;

        $(this.$refs.modal).modal('show');
      }
    },
    mounted() {
      dispatch(new LoadNotCompletedEmployeesJob(parseInt(this.$route.params.id), 0));
    },
    components: {
      LeftEmployeeForm,
      EmployeeForm
    }
  }
</script>
