import {DispatchContext, Job} from "@/core/types";
import {getPayslip} from "@/core/api/payslip";

export default class GetPayslipJob implements Job{
  private userId: number;
  private year: string;
  private month: string;

  constructor(userId: number, year: string, month: string) {
    this.year = year;
    this.month = month;
    this.userId = userId;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getPayslip(this.userId, this.year, this.month);

    context.store.commit('payslip/setPayslip', response.data.data);
  }
}
