import {DispatchContext, Job} from "@/core/types";
import {AccountData, createAccount} from "@/core/api/account";

export default class CreateAccountJob implements Job {
  private readonly data: AccountData;

  constructor(data: AccountData) {
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await createAccount(this.data);
  }
}
