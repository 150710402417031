<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-list"></i> 사원 목록
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th></th>
            <th>사원번호</th>
            <th>이름</th>
            <th>생년월일</th>
            <th>직급</th>
            <th>부서</th>
            <th>근무타입</th>
            <th>급여타입</th>
            <th>입사일</th>
            <th>퇴사일</th>
            <th width="120"></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(employee, index) in employees">
            <tr>
              <td align="center">{{ total - (per_page * (page - 1)) - index }}</td>
              <td>{{ employee.code }}</td>
              <td>{{ employee.name }}</td>
              <td>{{ employee.birthday }}</td>
              <td>{{ employee.position }}</td>
              <td>{{ employee.department }}</td>
              <td>{{ employee.employee_type }}</td>
              <td>{{ employee.pay_type }}</td>
              <td>{{ employee.in_date }}</td>
              <td>{{ employee.out_date }}</td>
              <td>
                <router-link
                  :to="{name: 'user.edit.employee.edit', params: {id: employee.id}}"
                  class="btn btn-default btn-sm"
                >
                  수정
                </router-link>
                &nbsp;
                <button type="button" class="btn btn-danger btn-sm" @click="deleteEmployee(employee)">
                  삭제
                </button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <pagination
          :page="page"
          :per-page="per_page"
          :total="total"
          @move-page="movePage"
        />
      </div>
    </div>
    <div class="text-right">
      <router-link
        :to="{name: 'user.edit.employee.create', params: {id: userId}}"
        class="btn btn-primary btn-sm"
      >
        추가
      </router-link>
      &nbsp;
      <button type="button" class="btn btn-default btn-sm" @click="$router.back()">
        뒤로
      </button>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import {dispatch} from "@/core/dispatcher";
  import LoadEmployeesJob from "@/core/jobs/user/LoadEmployeesJob";
  import DestroyEmployeeJob from "@/core/jobs/employee/DestroyEmployeeJob";

  export default {
    data() {
      return {
        userId: 0
      }
    },
    computed: {
      ...mapState('user', {
        employees: state => state.employee.employees,
        page: state => state.employee.page,
        per_page: state => state.employee.per_page,
        total: state => state.employee.total
      })
    },
    methods: {
      deleteEmployee(employee) {
        if (confirm('정말 삭제하시겠습니까?')) {
          dispatch(new DestroyEmployeeJob([employee.id])).then(() => {
            dispatch(new LoadEmployeesJob(this.userId, this.page));
          });
        }
      },
      movePage(page) {
        dispatch(new LoadEmployeesJob(this.userId, page));
      }
    },
    mounted() {
      this.userId = parseInt(this.$route.params.id);
    }
  }
</script>
