<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user"></i> 사용자 정보
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="userid">아이디</label>
              <input type="text" class="form-control" readonly :value="userInput.userid" id="userid">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="name">회사명</label>
              <v-input
                type="text"
                v-model="userInput.name"
                :errors="errors.name"
                id="name"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="phone_number">휴대전화번호</label>
              <v-input
                type="tel"
                v-model="userInput.phone_number"
                :errors="errors.phone_number"
                id="phone_number"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="email">이메일</label>
              <v-input
                type="email"
                v-model="userInput.email"
                :errors="errors.email"
                id="email"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="company_number">사업자등록번호</label>
              <v-input
                type="text"
                v-model="userInput.company_number"
                :errors="errors.company_number"
                id="company_number"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group form-inline">
              <label for="pay_day_type">급여지급일</label><br />
              <select class="form-control" v-model="userInput.pay_day_type" id="pay_day_type">
                <option value="this_month">당월</option>
                <option value="next_month">익월</option>
              </select>
              &nbsp;
              <input type="number" class="form-control" size="2" v-model="userInput.pay_day">일
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="password">비밀번호</label>
              <v-input
                type="password"
                v-model="userInput.password"
                :errors="errors.password"
                id="password"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="password_confirmation">비밀번호 확인</label>
              <v-input
                type="password"
                v-model="userInput.password_confirmation"
                :errors="errors.password_confirmation"
                id="password_confirmation"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-heading">산출식</div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="annuity_tax_rate">국민연금</label>
              <v-input
                  type="text"
                  v-model="userInput.annuity_tax_rate"
                  :errors="errors.annuity_tax_rate"
                  id="annuity_tax_rate"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="hire_insurance_rate">고용보험</label>
              <v-input
                  type="text"
                  v-model="userInput.hire_insurance_rate"
                  :errors="errors.hire_insurance_rate"
                  id="hire_insurance_rate"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="health_insurance_rate">건강보험</label>
              <v-input
                  type="text"
                  v-model="userInput.health_insurance_rate"
                  :errors="errors.health_insurance_rate"
                  id="health_insurance_rate"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="treatment_insurance_rate">장기요양보험</label>
              <v-input
                  type="text"
                  v-model="userInput.treatment_insurance_rate"
                  :errors="errors.treatment_insurance_rate"
                  id="treatment_insurance_rate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="update">
        수정
      </button>
      &nbsp;
      <button type="button" class="btn btn-default btn-sm" @click="$router.back()">
        뒤로
      </button>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import {dispatch} from "@/core/dispatcher";
  import UpdateUserJob from "@/core/jobs/user/UpdateUserJob";

  export default {
    data() {
      return {
        userInput: {},
        errors: {}
      };
    },
    computed: {
      ...mapState('user', {
        user: state => state.selectedUser
      })
    },
    methods: {
      update() {
        let data = this.userInput;

        if (data.password === '' || data.password_confirmation === '') {
          delete data.password;
          delete data.password_confirmation;
        }

        dispatch(new UpdateUserJob(parseInt(this.user.id), this.userInput))
          .then(() => {
            this.errors = {};
            this.userInput.password = '';
            this.userInput.password_confirmation = '';

            alert('수정 완료');
          })
          .catch(err => {
            this.errors = err.response.data;
          });
      }
    },
    mounted() {
      this.userInput = {
        userid: this.user.userid,
        name: this.user.name,
        phone_number: this.user.phone_number,
        email: this.user.email,
        company_number: this.user.company_number,
        pay_day_type: this.user.pay_day_type,
        pay_day: this.user.pay_day,
        password: '',
        password_confirmation: '',
        extend_salary_rate: this.user.extend_salary_rate,
        night_salary_rate: this.user.night_salary_rate,
        holiday_salary_rate: this.user.holiday_salary_rate,

        annuity_tax_rate: this.user.annuity_tax_rate,
        hire_insurance_rate: this.user.hire_insurance_rate,
        health_insurance_rate: this.user.health_insurance_rate,
        treatment_insurance_rate: this.user.treatment_insurance_rate,
      };
    }
  }
</script>
