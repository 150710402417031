<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="glyphicon glyphicon-list-alt"></i> 관리자 계정 목록
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <colgroup>
            <col width="50" />
            <col width="120" />
            <col width="*" />
            <col width="*" />
            <col width="120" />
          </colgroup>
          <thead>
          <tr>
            <th></th>
            <th>아이디</th>
            <th>이메일</th>
            <th>휴대전화번호</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(account, index) in accounts">
            <tr>
              <td align="center">{{ accounts.length - index }}</td>
              <td>{{ account.userid }}</td>
              <td>{{ account.email }}</td>
              <td>{{ account.phone_number }}</td>
              <td>
                <router-link
                  :to="{name: 'account.manage.edit', params: {id: account.id}}"
                  class="btn btn-default btn-sm"
                >
                  수정
                </router-link>
                &nbsp;
                <button type="button" class="btn btn-default btn-sm" @click="destroy(account)">
                  삭제
                </button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>

    <div class="text-right">
      <router-link
        :to="{name: 'account.manage.create'}"
        class="btn btn-primary btn-sm"
      >
        계정 생성
      </router-link>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import {dispatch} from "@/core/dispatcher";
  import DestroyAccountJob from "@/core/jobs/account/DestroyAccountJob";
  import LoadAccountsJob from "@/core/jobs/account/LoadAccountsJob";

  export default {
    computed: {
      ...mapState('account', {
        accounts: state => state.accounts
      })
    },
    methods: {
      destroy(account) {
        if (! confirm('정말 삭제하시겠습니까?')) {
          return;
        }

        dispatch(new DestroyAccountJob([account.id])).then(() => {
          dispatch(new LoadAccountsJob());
        })
      }
    }
  }
</script>
