<template>
  <div class="card">
    <div class="card-heading">
      <i :class="icon"></i>{{ title }}
    </div>
    <div class="card-body">
      <table class="table table-bordered">
        <colgroup>
          <col width="50" />
          <col width="*" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>항목명</th>
          </tr>
        </thead>
        <tbody>
        <template v-for="property in properties">
          <tr :key="`property_${property.id}`">
            <td align="center">
              <input type="checkbox" :checked="value.indexOf(property.id) >= 0" :value="property.id" @input="change">
            </td>
            <td>
              {{ property.name }}
            </td>
          </tr>
        </template>
        </tbody>
      </table>
      <pagination
        :page="page"
        :perPage="per_page"
        :total="total"
        @move-page="movePage"
      />
    </div>
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import LoadBasePropertyJob from "@/core/jobs/base-property/LoadBasePropertyJob";

  export default {
    props: ['value', 'title', 'icon', 'type', 'page', 'per_page', 'total', 'properties', 'value'],
    methods: {
      change(ev) {
        let data = [
          ...this.value
        ];

        if (ev.target.checked) {
          data.push(parseInt(ev.target.value));
        } else {
          data.splice(data.indexOf(ev.target.value, 1));
        }

        this.$emit('input', data);
      },
      movePage(page) {
        dispatch(new LoadBasePropertyJob({
          type: this.type,
          page
        }));
      }
    }
  }
</script>
