<template>
  <div class="app-modal" v-if="open">
    <div class="container">
      <div class="card">
        <div class="card-body">
          <div class="text-right">
            <button type="button" class="btn btn-default btn-sm" @click="close">
              닫기
            </button>
          </div>
          <br />
          <div class="card">
            <div class="card-heading">
              <i class="fa fa-file-image"></i> 이미지 업로드
            </div>
            <div class="card-body">
              <div class="form-group">
                <label for="image">이미지</label>
                <v-input-file
                  id="image"
                  v-model="image"
                />
              </div>
              <div class="form-group text-right">
                <button type="button" class="btn btn-primary btn-sm" @click="upload">
                  업로드
                </button>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body image-selector">
              <div :style="sliderWidth" class="clearfix">
                <template v-for="image in images">
                  <div class="image" :key="`image-${image.id}`">
                    <img :src="image.url" width="200" @click="selectImage(image.url)">
                    <div class="delete-icon" @click.prevent="deleteImage(image)">
                      <i class="fas fa-times"></i>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <pagination
            :total="total"
            :page="page"
            :per-page="per_page"
            @move-page="movePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import UploadImageJob from "@/core/jobs/image/UploadImageJob";
  import LoadImagesJob from "@/core/jobs/image/LoadImagesJob";
  import {mapState} from 'vuex';
  import SelectImageJob from "@/core/jobs/image/SelectImageJob";
  import DestroyImageJob from "@/core/jobs/image/DestroyImageJob";

  export default {
    data() {
      return {
        image: null
      }
    },
    computed: {
      ...mapState('imageModal', {
        images: state => state.images,
        total: state => state.total,
        per_page: state => state.per_page,
        page: state => state.page,
        open: state => state.open
      }),
      sliderWidth() {
        return {
          width: (this.images.length * 210) + 'px'
        }
      }
    },
    methods: {
      deleteImage(image) {
        if (! confirm('정말 삭제하시겠습니까?')) {
          return;
        }

        dispatch(new DestroyImageJob(image.id)).then(() => {
          dispatch(new LoadImagesJob(this.page));
        });
      },
      close() {
        this.$store.commit('imageModal/closeModal');
      },
      selectImage(url) {
        dispatch(new SelectImageJob(url));
      },
      upload() {
        if (this.image === null) {
          alert('이미지를 선택해주세요.');
          return;
        }

        dispatch(new UploadImageJob(this.image)).then(() => {
          alert('업로드 완료');
          this.image = null;

          dispatch(new LoadImagesJob(this.page));
        });
      },
      movePage(page) {
        dispatch(new LoadImagesJob(page));
      }
    },
    mounted() {
      dispatch(new LoadImagesJob(0));
    }
  }
</script>
