import {DispatchContext, Job} from "@/core/types";
import * as basePropertyApi from '@/core/api/base-property';

export default class SyncUserAndBasePropertyJob implements Job {
  private readonly userId: number;
  private readonly idList: Array<number>;

  constructor(userId: number, idList: Array<number>) {
    this.idList = idList;
    this.userId = userId;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await basePropertyApi.sync({
      userId: this.userId,
      propertyIdList: this.idList
    });

    context.store.commit('user/updateUser', {
      id: this.userId,
      data: response.data.data
    })
  }
}
