<template>
  <div>
    <h4 class="page-title">관리자 계정 관리</h4>
    <account-list />
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import LoadAccountsJob from "@/core/jobs/account/LoadAccountsJob";
  import AccountList from "@/components/account/manage/AccountList";

  export default {
    components: {
      AccountList
    },
    mounted() {
      dispatch(new LoadAccountsJob());
    }
  }
</script>
