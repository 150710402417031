<template>
  <div>
    <h4 class="page-title">입/퇴사 사원 관리</h4>

    <user-list />
  </div>
</template>
<script>
  import UserList from '@/components/user/employee/index/UserList';

  export default {
    components: {
      UserList
    }
  }
</script>
