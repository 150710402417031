import {DispatchContext, Job} from "@/core/types";
import * as employeeApi from '@/core/api/employee';

export default class DestroyEmployeeJob implements Job {
  private id: Array<number>;

  constructor(id: Array<number>) {
    this.id = id;
  }

  async run(context: DispatchContext): Promise<any> {
    await employeeApi.deleteEmployees(this.id);
  }
}
