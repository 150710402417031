<template>
  <div>
    <h4 class="page-title">급여/공제 항목 관리</h4>

    <property-form />
    <br />
    <div class="row">
      <div class="col-md-6">
        <property-list
          type="wage"
          icon="fa fa-plus-square"
          label="급여항목"
          :properties="wage.properties"
          :page="wage.page"
          :per_page="wage.per_page"
          :total="wage.total"
        />
      </div>
      <div class="col-md-6">
        <property-list
          type="exclude"
          icon="fa fa-minus-square"
          label="공제항목"
          :data="exclude"
          :properties="exclude.properties"
          :page="exclude.page"
          :per_page="exclude.per_page"
          :total="exclude.total"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import PropertyForm from '@/components/base-property/manage/index/PropertyForm';
  import {dispatch} from "@/core/dispatcher";
  import LoadBasePropertyJob from "@/core/jobs/base-property/LoadBasePropertyJob";
  import PropertyList from '@/components/base-property/manage/index/PropertyList';

  export default {
    computed: {
      ...mapState('baseProperty', {
        wage: state => state.wage,
        exclude: state => state.exclude
      })
    },
    components: {
      PropertyForm,
      PropertyList
    },
    mounted() {
      dispatch([
        new LoadBasePropertyJob({
          type: 'wage'
        }),
        new LoadBasePropertyJob({
          type: 'exclude'
        })
      ]);
    }
  }
</script>
