import Page from '@/views/admin/log/Page.vue';
import AlarmIndex from '@/views/admin/log/alarm/Index.vue';

export default [
  {
    path: 'log',
    component: Page,
    children: [
      {
        path: 'alarm',
        component: AlarmIndex,
        name: 'log.alarm.index'
      }
    ]
  }
]
