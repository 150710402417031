import {DispatchContext, Job} from "@/core/types";

import * as userApi from '@/core/api/user';
import {SearchData} from "@/core/api/user";

export default class LoadUserListJob implements Job {
  private searchData: SearchData;

  constructor(searchData: SearchData) {
    this.searchData = searchData;
  }

  async run(context: DispatchContext): Promise<any> {
    this.searchData.adminId = context.store.state.auth.admin.id;
    this.searchData.certified = true;

    const response = await userApi.paginate(this.searchData);

    context.store.commit('user/setUser', response.data.data);
  }
}
