import {DispatchContext, Job} from "@/core/types";
import {getNotCompletedEmployees} from "@/core/api/employee";

export default class LoadNotCompletedEmployeesJob implements Job{
  private userId: number;
  private page: number;

  constructor(userId: number, page: number) {
    this.userId = userId;
    this.page = page;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getNotCompletedEmployees(this.userId, this.page);

    context.store.commit('employeeManage/setEmployees', response.data.data);
  }
}
