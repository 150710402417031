import {DispatchContext, Job} from "@/core/types";
import {uploadFile} from "@/core/api/payslip";

export default class UploadFileJob implements Job {
  private id: string;
  private type: string;
  private file1: any;
  private file2: any;
  private file3: any;

  constructor(id: string, type: string, file1: any, file2: any, file3: any) {
    this.id = id;
    this.type = type;
    this.file1 = file1;
    this.file2 = file2;
    this.file3 = file3;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await uploadFile(this.id, this.type, {
      file1: this.file1,
      file2: this.file2,
      file3: this.file3
    });

    console.log(response);
  }
}
