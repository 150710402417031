<template>
  <div>
    <search-form
      @search="search"
    />
    <div class="card">
      <div class="card-heading">
        <i class="glyphicon glyphicon-list-alt"></i> 사용자 목록
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th width="50"></th>
              <th>아이디</th>
              <th>회사명</th>
              <th>휴대전화번호</th>
              <th>이메일</th>
              <th>사업자등록번호</th>
              <th>급여지급일</th>
              <th width="180"></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(user, index) in users">
              <tr>
                <td class="text-center">
                  {{ total - (per_page * (page - 1)) - index }}<br />
                </td>
                <td>
                  {{ user.userid }}
                </td>
                <td>
                  {{ user.name }}
                </td>
                <td>
                  {{ user.phone_number }}
                </td>
                <td>
                  {{ user.email }}
                </td>
                <td>
                  {{ user.company_number }}
                </td>
                <td>
                  {{ user.pay_day_type === 'this_month' ? '당월' : '익월' }}
                  {{ user.pay_day }}일
                </td>
                <td>
                  <router-link
                    :to="{name: 'user.edit.employee.index', params: {id: user.id}}"
                    class="btn btn-default btn-sm"
                  >
                    사원 목록
                  </router-link>
                  &nbsp;
                  <router-link :to="{name: 'user.edit', params: {id: user.id}}" class="btn btn-default btn-sm">
                    수정
                  </router-link>
                  &nbsp;
                  <button type="button" class="btn btn-danger btn-sm" @click="destroyUser(user)">
                    삭제
                  </button>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <pagination
      :page="page"
      :per-page="per_page"
      :total="total"
      @move-page="movePage"
    />
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import {dispatch} from "@/core/dispatcher";
  import LoadUserListJob from "@/core/jobs/user/LoadUserListJob";
  import SearchForm from './SearchForm';
  import DestroyUserJob from "@/core/jobs/user/DestroyUserJob";

  export default {
    data() {
      return {
        selectedUsers: []
      };
    },
    computed: {
      ...mapState('user', {
        users: state => state.users,
        page: state => state.page,
        total: state => state.total,
        per_page: state => state.per_page
      })
    },
    methods: {
      destroyUser(user) {
        if (!confirm('정말 삭제하시겠습니까?')) {
          return false;
        }

        dispatch(new DestroyUserJob(user.id));
      },
      search({ userid }) {
        dispatch(new LoadUserListJob({
          page: 0,
          userid
        }))
      },
      movePage(page) {
        dispatch(new LoadUserListJob({
          page
        }));
      }
    },
    components: {
      SearchForm
    }
  }
</script>
