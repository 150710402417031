import {DispatchContext, Job} from "@/core/types";
import {PropertySearch} from "@/core/api/base-property";

import * as basePropertyApi from '@/core/api/base-property';

export default class LoadBasePropertyJob implements Job {
  private readonly data: PropertySearch;

  constructor(data: PropertySearch) {
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await basePropertyApi.getProperties(this.data);

    if (this.data.type === 'wage') {
      context.store.commit('baseProperty/setWageProperties', response.data.data);
    } else {
      context.store.commit('baseProperty/setExcludeProperties', response.data.data);
    }
  }
}
