<template>
  <div>
    <h4 class="page-title">세무지식</h4>

    <knowhow-list />
  </div>
</template>
<script>
  import KnowhowList from "@/components/announcement/notice/KnowhowList";
  import {dispatch} from "@/core/dispatcher";
  import LoadAnnouncementJob from "@/core/jobs/announcement/LoadAnnouncementJob";

  export default {
    components: {
      KnowhowList
    },
    mounted() {
      dispatch(new LoadAnnouncementJob(0, 'knowhow'));
    }
  }
</script>
