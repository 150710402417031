<template>
  <div class="form-inline">
    <div class="form-group">
      <select class="form-control" v-model="year">
        <template v-for="index in (maxYear - 2010)">
          <option :value="2010 + index">{{ 2010 + index }}년</option>
        </template>
      </select>
    </div>
    &nbsp;
    <div class="form-group">
      <select class="form-control" v-model="month">
        <template v-for="month in months">
          <option :value="month">{{ month }}월</option>
        </template>
      </select>
    </div>
    &nbsp;
    <div class="form-group">
      <button type="button" class="btn btn-primary" @click="search">
        확인
      </button>
    </div>
  </div>
</template>
<script>
  import moment from "moment";

  export default {
    props: ['value'],
    data() {
      return {
        year: '',
        month: '',
        months: [
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
        ]
      };
    },
    computed: {
      maxYear() {
        return moment().format('YYYY');
      }
    },
    methods: {
      search() {
        this.$emit('input', {
          year: this.year,
          month: this.month
        });
        this.$router.push({
          name: 'payslip.index',
          query: {
            year: this.year,
            month: this.month
          }
        }).catch(() => {});
      }
    },
    mounted() {
      this.year = this.value.year;
      this.month = this.value.month;
    }
  }
</script>
