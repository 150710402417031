import {DispatchContext, Job} from "@/core/types";
import {getUsers} from "@/core/api/payslip";

export default class GetUsersJob implements Job {
  private type: string;
  private page: number;
  private year: string;
  private month: string;

  constructor(type: string, year: string, month: string, page: number) {
    this.page = page;
    this.type = type;
    this.year = year;
    this.month = month;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getUsers(this.type, this.year, this.month, this.page);

    context.store.commit('payslip/setPayslips', {
      type: this.type,
      data: response.data.data
    });
  }
}
