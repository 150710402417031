import store from '../store';
import * as authManager from '@/core/auth/manager';
import {DispatchContext, Job} from "@/core/types";

export function dispatch(job: Job | Array<Job>): Promise<any> {
  store.commit('setIsLoading', true);

  const context: DispatchContext = {
    store,
    authManager: {
      login: authManager.login,
      logout: authManager.logout
    }
  };

  if (Array.isArray(job)) {
    let promises = [];

    for (let i = 0; i < job.length; i ++) {
      promises.push(job[i].run(context));
    }

    return Promise.all(promises).then(responses => {
      store.commit('setIsLoading', false);

      return responses;
    }).catch(err => {
      store.commit('setIsLoading', false);

      return Promise.reject(err);
    });
  } else {
    return job.run(context)
      .then(response => {
        store.commit('setIsLoading', false);

        return response;
      })
      .catch(err => {
        store.commit('setIsLoading', false);

        return Promise.reject(err);
      });
  }
}
