<template>
  <div class="v-date-picker">
    <datepicker
      :input-class="['form-control', {'form-error': errors !== undefined}]"
      format="yyyy-MM-dd"
      :id="id"
      :value="value"
      :language="ko"
      @input="changed"
      :typeable="true"
      ref="input"
    />
    <div class="error-message" v-if="errors !== undefined">
      <template v-for="error in errors">
        {{ error }}<br />
      </template>
    </div>
  </div>
</template>
<script>
  import Datepicker from 'vuejs-datepicker';
  import {ko} from 'vuejs-datepicker/dist/locale';
  import moment from 'moment';

  export default {
    props: ['errors', 'value', 'id'],
    data() {
      return {
        ko
      };
    },
    methods: {
      changed(v) {
        if (v === null) this.$emit('input', '');
        else this.$emit('input', moment(v).format('YYYY-MM-DD'));
      }
    },
    components: {
      Datepicker
    }
  }
</script>
