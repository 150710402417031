import {PaginateData} from "@/core/types";

export default {
  namespaced: true,
  state: {
    user_cancel: {
      total: 0,
      per_page: 0,
      page: 0,
      data: []
    },
    pending: {
      total: 0,
      per_page: 0,
      page: 0,
      data: []
    },
    user_wrote: {
      total: 0,
      per_page: 0,
      page: 0,
      data: []
    },
    admin_confirm: {
      total: 0,
      per_page: 0,
      page: 0,
      data: []
    },
    finished: {
      total: 0,
      per_page: 0,
      page: 0,
      data: []
    },
    employees: {
      total: 0,
      per_page: 0,
      page: 0,
      data: []
    },
    selectedUser: null,
    payslip: null,
    userIndex: 0,
    payslipType: 'normal'
  },
  getters: {
    currentEmployee(state: any) {
      if (state.payslip === null) return {};

      const employees = state.payslip.employees.filter((employee: any) => employee.id === state.userIndex);
      if (employees.length === 0) return null;

      return employees[0];
    },
    employees(state: any) {
      if (state.payslipType === 'normal') {
        return state.payslip.employees.filter((employee: any) => employee.employee_type === '일반근로자');
      } else if (state.payslipType === 'business') {
        return state.payslip.employees.filter((employee: any) => employee.employee_type === '사업소득자');
      } else {
        return state.payslip.employees.filter((employee: any) => employee.employee_type === '일용근로자');
      }
    }
  },
  mutations: {
    changePayslipType(state: any, type: string) {
      state.payslipType = type;
    },
    updateUserIndex(state: any, id: number) {
      state.userIndex = id;
    },
    setPayslip(state: any, data: any) {
      state.payslip = data;
    },
    setPayslips(state: any, {type, data}: any) {
      state[type].total = data.total;
      state[type].per_page = data.per_page;
      state[type].page = data.page;
      state[type].data = data.data;
    },
    setEmployees(state: any, data: PaginateData) {
      state.employees.total = data.total;
      state.employees.per_page = data.per_page;
      state.employees.page = data.page;
      state.employees.data = data.data;
    },
    selectUser(state: any, data: any) {
      state.selectedUser = {
        ...data
      };
    }
  }
}
