import {DispatchContext, Job} from "@/core/types";
import * as employeeApi from '@/core/api/employee';

export default class LoadNotCompletedUsersJob implements Job {
  private readonly page: number;

  constructor(page: number) {
    this.page = page;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await employeeApi.getNotCompletedUsers(context.store.state.auth.admin.id, this.page);

    context.store.commit('employeeManage/setUsers', response.data.data);
  }
}
