import {DispatchContext, Job} from "@/core/types";
import {AccountData, updateAccount} from "@/core/api/account";

export default class UpdateAccountJob implements Job {
  private readonly data: AccountData;
  private readonly id: number;

  constructor(id: number, data: AccountData) {
    this.id = id;
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await updateAccount(this.id, this.data);
  }
}
