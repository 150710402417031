import * as http from './http';

export interface UpdateAccountData {
  email: string,
  phone_number: string,
  password: string,
  password_confirmation: string
}

export function admin() {
  return http.get('/api/v1/admin/auth/admin');
}

export function login(userid: string, password: string) {
  return http.post('/api/v1/admin/auth/login', {
    userid,
    password
  })
}

export function update(data: UpdateAccountData) {
  return http.put('/api/v1/admin/auth', data);
}
