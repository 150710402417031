<template>
  <div>
    <div class="alert alert-success alert-sm">
      <router-link
        :to="{name: 'user.edit', params: {id: group.user.id}}"
      >
        {{ group.user.name }}
      </router-link>
    </div>
    <div class="row">
      <template v-for="employee in group.data">
        <div class="col-md-6" :key="`employee-${employee.id}`">
          <employee
            :employee="employee"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import Employee from './EmployeeForm';

  export default {
    props: ['group'],
    components: {
      Employee
    }
  }
</script>
