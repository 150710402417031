import Page from '@/views/admin/payslip/Page.vue';

import PayslipIndexView from '@/views/admin/payslip/index/Index.vue';
import PayslipUserView from '@/views/admin/payslip/index/Show.vue';

export default [
  {
    path: 'payslip',
    component: Page,
    children: [
      {
        path: '',
        component: PayslipIndexView,
        name: 'payslip.index'
      },
      {
        path: ':id',
        component: PayslipUserView,
        name: 'payslip.show'
      }
      /*
      {
        path: 'input',
        component: InputIndexView,
        name: 'payslip.input.index'
      },
      {
        path: 'input/:id/employee',
        component: InputEmployeeView,
        name: 'payslip.input.employee'
      },
      {
        path: 'input/:id/exclude',
        component: InputExcludeView,
        name: 'payslip.input.exclude'
      }
      */
    ]
  }
]
