<template>
  <div class="auth">
    <div class="container">
      <div class="auth-form">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
  export default {

  }
</script>
