<template>
  <div>
    <h4 class="page-title">급여관련 문의 담당자</h4>

    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user"></i> 담당자 추가
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="name">담당자명</label>
              <input type="text" class="form-control" id="name" v-model="name">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="phone_number">전화번호</label>
              <input type="text" class="form-control" id="phone_number" v-model="phone_number">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="email">이메일</label>
              <input type="text" class="form-control" id="email" v-model="email">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="save">
        추가
      </button>
    </div>
    <br />
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-list"></i> 목록
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>이름</th>
            <th>전화번호</th>
            <th>이메일</th>
            <th width="80"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="cscenter in cscenterList">
            <td>{{ cscenter.name }}</td>
            <td>{{ cscenter.phone_number }}</td>
            <td>{{ cscenter.email }}</td>
            <td>
              <button type="button" class="btn btn-danger btn-sm" @click="destroy(cscenter.id)">삭제</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import * as configApi from '@/core/api/config';

  export default {
    data() {
      return {
        name: '',
        phone_number: '',
        email: '',
        cscenterList: []
      };
    },
    methods: {
      loadConfig() {
        configApi.get('cscenter').then(response => {
          this.cscenterList = response.data.map(d => ({id: d.id, ...JSON.parse(d.value)}));
        });
      },
      save() {
        if (this.name === '' || this.phone_number === '' || this.email === '') {
          alert('모든 항목을 입력해주세요.');
          return;
        }

        configApi.addConfig('cscenter', JSON.stringify({
          name: this.name,
          phone_number: this.phone_number,
          email: this.email
        })).then(() => {
          alert('추가 완료');

          this.name = '';
          this.phone_number = '';
          this.email = '';

          this.loadConfig();
        });
      },
      destroy(id) {
        if (! confirm('정말 삭제하시겠습니까?')) {
          return;
        }

        configApi.removeConfig(id).then(() => {
          this.loadConfig();
        });
      }
    },
    mounted() {
      this.loadConfig();
    }
  }
</script>
