import {DispatchContext, Job} from "@/core/types";
import * as basePropertyApi from '@/core/api/base-property';

export default class DestroyBasePropertyJob implements Job {
  private readonly id: Array<number>;
  private readonly type: string;

  constructor(id: Array<number>, type: string) {
    this.id = id;
    this.type = type;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await basePropertyApi.destroyProperty(this.id);

    if (response.data.data.error_count > 0) {
      alert('사용중인 항목은 삭제할 수 없습니다.');
      return;
    }

    if (this.type === 'wage') {
      const response = await basePropertyApi.getProperties({
        type: 'wage',
        page: context.store.state.baseProperty.wage.page
      });

      context.store.commit('baseProperty/setWageProperties', response.data.data);
    } else {
     const response = await basePropertyApi.getProperties({
       type: 'exclude',
       page: context.store.state.baseProperty.exclude.page
     });

     context.store.commit('baseProperty/setExcludeProperties', response.data.data);
    }
  }
}
