import Page from '@/views/admin/base-property/Page.vue';
import ManageIndex from '@/views/admin/base-property/manage/Index.vue';

export default [
  {
    path: 'base-property',
    component: Page,
    children: [
      {
        path: 'manage',
        component: ManageIndex,
        name: 'base-property.index'
      }
    ]
  }
];
