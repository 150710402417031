import * as http from './http';

export interface AlarmLogSearchData {
  user_name: string,
  from: string,
  to: string
}

export function getLogs(page: number, data: AlarmLogSearchData) {
  return http.get(`/api/v1/admin/alarm-log?page=${page}&user_name=${data.user_name}&from=${data.from}&to=${data.to}`);
}
