import {DispatchContext, Job} from "@/core/types";
import {adminConfirm} from "@/core/api/payslip";

export default class AdminConfirmJob implements Job {
  private userId: string;
  private year: string;
  private month: string;

  constructor(userId: string, year: string, month: string) {
    this.userId = userId;
    this.year = year;
    this.month = month;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await adminConfirm(this.userId, this.year, this.month);
  }
}
