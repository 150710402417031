<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-list"></i> {{ title }}
      </div>
      <div class="card-body">
        <div class="list-group">
          <template v-for="user in users">
            <button
              type="button"
              class="list-group-item"
              @click="select(user)"
              :class="{'list-group-item-danger': user.payslip !== null && user.payslip.state === 'user_rejected'}"
            >
              {{ user.name }}
              <strong v-if="user.payslip !== null && user.payslip.state === 'user_rejected'">
                ( 거절됨 )
              </strong>
            </button>
          </template>
        </div>
        <pagination
          :total="total"
          :per-page="per_page"
          :page="page"
          @move-page="movePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import GetUsersJob from "@/core/jobs/payslip/GetUsersJob";

  export default {
    props: ['type', 'title', 'date'],
    data() {
      return {
      };
    },
    watch: {
      date(v) {
        dispatch(new GetUsersJob(this.type, v.year, v.month));
      }
    },
    computed: {
      users() {
        return this.$store.state.payslip[this.type].data;
      },
      total() {
        return this.$store.state.payslip[this.type].total;
      },
      per_page() {
        return this.$store.state.payslip[this.type].per_page;
      },
      page() {
        return this.$store.state.payslip[this.type].page;
      }
    },
    methods: {
      select(user) {
        this.$store.commit('payslip/selectUser', user);

        this.$router.push({
          name: 'payslip.show',
          params: {id: user.id},
          query: {year: this.date.year, month: this.date.month}
        });
      },
      movePage(page) {
        dispatch(new GetUsersJob(this.type, this.date.year, this.date.month, page));
      }
    },
    mounted() {
      dispatch(new GetUsersJob(this.type, this.date.year, this.date.month, 0));
    }
  }
</script>
