import {DispatchContext, Job} from "@/core/types";
// @ts-ignore
import jscookie from 'js-cookie';

export default class LogoutJob implements Job {
  async run(context: DispatchContext): Promise<any> {
    await context.authManager.logout();

    context.store.commit('auth/setAdmin', null);
  }
}
