import Auth from '../Auth.vue';

import LoginView from '../views/auth/Login.vue';

export default [
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: 'login',
        component: LoginView,
        name: 'login'
      }
    ]
  }
];
