<template>
  <div class="card">
    <div class="card-heading">
      사용자 검색
    </div>
    <div class="card-body">
      <div class="form-group">
        <label for="target">아이디/회사명</label>
        <input type="text" class="form-control" v-model="target" id="target">
        <div class="text-danger">
          아이디 혹은 회사명을 입력하지 않으면 전체 사용자에게 푸시가 전송됩니다.
        </div>
      </div>
      <div class="form-group">
        <label for="message">메시지</label>
        <textarea id="message" v-model="message" class="form-control" rows="5" />
      </div>
      <div class="text-right">
        <button type="button" class="btn btn-primary" @click="send">
          전송
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import * as notificationApi from '@/core/api/notification';

  export default {
    data() {
      return {
        target: '',
        message: ''
      };
    },
    methods: {
      send() {
        if (! confirm('메시지를 전송하시겠습니까?')) {
          return;
        }

        notificationApi.sendPush(this.target, this.message).then(() => {
          alert('전송하였습니다.');
          this.message = '';
        }).catch(() => {
          alert('전송에 실패하였습니다.');
        });
      }
    }
  }
</script>
