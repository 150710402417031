<template>
  <div>
    <h4 class="page-title">푸시 전송</h4>

      <SearchUser />
  </div>
</template>
<script>
  import SearchUser from "@/components/user/notification/SearchUser";

  export default {
    components: {
      SearchUser
    }
  }
</script>
