<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-list"></i> 사용자 목록
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th width="70"></th>
            <th>아이디</th>
            <th>회사명</th>
            <th>휴대전화번호</th>
            <th>이메일</th>
            <th>사업자등록번호</th>
            <th width="100"></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(user, index) in users">
            <tr :key="`user-list-${user.id}`">
              <td align="center">
                {{ total - (per_page * (page - 1)) - index }}
              </td>
              <td>
                {{ user.id }}
              </td>
              <td>
                {{ user.name }}
              </td>
              <td>
                {{ user.phone_number }}
              </td>
              <td>
                {{ user.email }}
              </td>
              <td>
                {{ user.company_number }}
              </td>
              <td>
                <router-link
                  class="btn btn-default btn-sm"
                  :to="{name: 'user.employee.show', params: {id: user.id}}"
                >
                  사원 확인
                </router-link>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <pagination
          :total="total"
          :per-page="per_page"
          :page="page"
          @move-page="movePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import EmployeeGroup from './employee/Index';
  import {dispatch} from "@/core/dispatcher";
  import LoadNotCompletedUsersJob from "@/core/jobs/employee/LoadNotCompletedUsersJob";
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('employeeManage', {
        users: state => state.users,
        page: state => state.page,
        per_page: state => state.per_page,
        total: state => state.page
      })
    },
    methods: {
      movePage(page) {
        dispatch(new LoadNotCompletedUsersJob(page));
      }
    },
    components: {
      EmployeeGroup
    },
    mounted() {
      dispatch(new LoadNotCompletedUsersJob(0));
    }
  }
</script>
