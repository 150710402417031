<template>
  <div>
    <div>
      <side-bar
        :menus="menus"
      />
    </div>
    <div class="page">
      <router-view />
    </div>
  </div>
</template>
<script>
  import SideBar from "@/components/layouts/SideBar";

  export default {
    data() {
      return {
        menus: [
          {
            icon: 'fa fa-user-shield',
            label: '관리자 계정 관리',
            routeName: 'account.manage.index',
            super: true
          }
        ]
      };
    },
    components: {SideBar}
  }
</script>
