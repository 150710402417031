<template>
  <div>
    <table class="table table-bordered table-hover">
      <thead>
      <tr>
        <th>항목명</th>
        <th>값</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(property, index) in value">
        <tr>
          <td>
            {{ property.name }}
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              @input="update($event, index)"
              :value="property.value"
            >
          </td>
        </tr>
      </template>
      </tbody>
    </table>

    <table width="100%" class="working-calendar">
      <template v-for="row in 6">
        <tr>
          <template v-for="col in 6">
            <td
              :class="{active: selectedDays.indexOf(`${((row - 1) * 6) + col}`) >= 0}"
              width="16%"
              @click="toggleDay(`${((row - 1) * 6) + col}`)"
              v-if="((row - 1) * 6) + col < 32"
            >
              {{ ((row - 1) * 6) + col }}
            </td>
          </template>
        </tr>
      </template>
    </table>
  </div>
</template>
<script>
  import {mapState} from "vuex";

  export default {
    props: ['value', 'employee'],
    computed: {
      ...mapState('payslip', {
        payslip: state => state.payslip
      }),
      selectedDays() {
        const v = this.value.filter(v => v.id === 1000);

        if (v.length === 0) return [];
        if (v[0].comment === null) return [];

        return v[0].comment.split(',');
      }
    },
    methods: {
      toggleDay(day) {
        let days = [...this.selectedDays];
        days = days.filter(v => v !== '');

        if (days.indexOf(day) < 0) {
          days.push(day);
        } else {
          days.splice(days.indexOf(day), 1);
        }

        this.$emit('input', [
          ...this.value.map(v => {

            if (v.id === 1000) {
              return {
                ...v,
                value: days.length,
                comment: days.join(',')
              };
            }

            return {
              ...v
            };
          })
        ])
      },
      update(ev, index) {
        const properties = [
          ...this.value
        ];

        properties[index] = {
          ...properties[index],
          value: ev.target.value
        };

        this.$emit('input', properties);
      }
    }
  }
</script>
