<template>
  <div>
    <side-bar
      :menus="menus"
    />
    <div class="page">
      <router-view
      />
    </div>
  </div>
</template>
<script>
  import SideBar from '@/components/layouts/SideBar';

  export default {
    data() {
      return {
        menus: [
          {
            icon: 'fa fa-megaphone',
            label: '공지사항 관리',
            routeName: 'announcement.notice.index',
            super: false
          },
          {
            icon: 'fa fa-badge-percent',
            label: '세무지식 관리',
            routeName: 'announcement.knowhow.index',
            super: false
          },
          {
            icon: 'fa fa-paste',
            label: '서식모음 관리',
            routeName: 'announcement.template.index',
            super: false
          },
          {
            icon: 'fa fa-user',
            label: '급여관련 문의 담당자',
            routeName: 'announcement.cscenter.index',
            super: false
          }
        ]
      }
    },
    components: {
      SideBar
    }
  }
</script>
