import {PaginateData} from "@/core/types";

export default {
  namespaced: true,
  state: {
    logs: [],
    total: 0,
    per_page: 0,
    page: 0,
    selectedLog: null
  },
  mutations: {
    selectLog(state: any, data: any) {
      state.selectedPage = {
        ...data
      };
    },
    setLogs(state: any, data: PaginateData) {
      state.logs = [
        ...data.data
      ];
      state.total = data.total;
      state.per_page = data.per_page;
      state.page = data.page;
    }
  }
}
