<template>
  <div v-if="loaded">
    <h4 class="page-title">공지사항 수정</h4>

    <edit-form />
  </div>
</template>
<script>
  import EditForm from "@/components/announcement/notice/EditNoticeForm";
  import {dispatch} from "@/core/dispatcher";
  import SelectAnnouncementJob from "@/core/jobs/announcement/SelectAnnouncementJob";

  export default {
    data() {
      return {
        loaded: false
      };
    },
    components: {
      EditForm
    },
    mounted() {
      dispatch(new SelectAnnouncementJob(parseInt(this.$route.params.id))).then(() => {
        this.loaded = true;
      });
    }
  }
</script>
