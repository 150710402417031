import {DispatchContext, Job} from "@/core/types";
import {uploadImage} from "@/core/api/image";

export default class UploadImageJob implements Job {
  private readonly file: any;

  constructor(file: any) {
    this.file = file;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await uploadImage({
      file: this.file
    });
  }
}
