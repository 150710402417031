import {PaginateData} from "@/core/types";

export default {
  namespaced: true,
  state: {
    wage: {
      properties: [],
      total: 0,
      page: 0,
      per_page: 0
    },
    exclude: {
      properties: [],
      total: 0,
      page: 0,
      per_page: 0
    }
  },
  mutations: {
    setWageProperties(state: any, data: PaginateData) {
      state.wage.properties = [
        ...data.data
      ];
      state.wage.total = data.total;
      state.wage.page = data.page;
      state.wage.per_page = data.per_page;
    },
    setExcludeProperties(state: any, data: PaginateData) {
      state.exclude.properties = [
        ...data.data
      ];
      state.exclude.total = data.total;
      state.exclude.page = data.page;
      state.exclude.per_page = data.per_page;
    }
  }
}
