<template>
  <div>
    <side-bar
      :menus="menus"
    />
    <div class="page">
      <router-view />
    </div>
  </div>
</template>
<script>
  import SideBar from "@/components/layouts/SideBar";

  export default {
    data() {
      return {
        menus: [
          {
            icon: 'fa fa-bell',
            label: '자동알림 로그',
            routeName: 'log.alarm.index',
            super: false
          }
        ]
      }
    },
    components: {
      SideBar
    }
  }
</script>
