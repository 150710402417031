import {DispatchContext, Job} from "@/core/types";
import {destroyAnnouncement} from "@/core/api/announcement";

export default class DestroyAnnouncementJob implements Job {
  private readonly id: number;

  constructor(id: number) {
    this.id = id;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await destroyAnnouncement(this.id);
  }
}
