<template>
  <div>
    <table class="table table-bordered">
      <tbody>
      <tr>
        <th>사원코드</th>
        <td>{{ employee.code }}</td>
      </tr>
      <tr>
        <th>이름</th>
        <td>{{ employee.name }}</td>
      </tr>
      <tr>
        <th>생년월일</th>
        <td>{{ employee.birthday }}</td>
      </tr>
      <tr>
        <th>직급</th>
        <td>{{ employee.position }}</td>
      </tr>
      <tr>
        <th>부서</th>
        <td>{{ employee.department }}</td>
      </tr>
      <tr>
        <th>입사일</th>
        <td>{{ employee.in_date }}</td>
      </tr>
      <tr>
        <th>퇴사일</th>
        <td>{{ employee.out_date }}</td>
      </tr>
      <tr>
        <th>근무타입</th>
        <td>{{ employee.employee_type }}</td>
      </tr>
      <tr>
        <th>급여타입</th>
        <td>{{ employee.pay_type }}</td>
      </tr>
      <tr>
        <th>주민등록번호</th>
        <td>{{ employee.jumin }}</td>
      </tr>
      <template v-if="! employee.in_completed">
        <tr>
          <th>취득 신고서</th>
          <td>
            <v-input-file
              :id="`regist_file_${employee.id}`"
              :value="value.regist"
              @input="update($event, 'regist')"
            />
          </td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <th>퇴직금</th>
          <td>
            {{ employee.severance_pay }}
          </td>
        </tr>
        <tr>
          <th>퇴직 신고서</th>
          <td>
            <v-input-file
              :id="`out_file_${employee.id}`"
              :value="value.out"
              @input="update($event, 'out')"
            />
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import UpdateEmployeeJob from "@/core/jobs/employee/UpdateEmployeeJob";
  import LoadNotCompletedUsersJob from "@/core/jobs/employee/LoadNotCompletedUsersJob";

  export default {
    props: ['employee', 'value'],
    data() {
      return {
        regist_file: null,
        out_file: null
      };
    },
    methods: {
      update(v, type) {
        if (type === 'regist') {
          this.regist_file = v;
        } else if (type === 'out') {
          this.out_file = v;
        }

        this.$emit('input', {
          regist: this.regist_file,
          out: this.out_file
        });
      },
      save() {
        dispatch(new UpdateEmployeeJob(this.employee.id, {
          regist_file: this.regist_file,
          out_file: this.out_file
        })).then(() => {
          this.regist_file = null;
          this.out_file = null;

          return dispatch(new LoadNotCompletedUsersJob(0));
        });
      }
    }
  }
</script>
