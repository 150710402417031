<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user-shield"></i> 계정 정보
      </div>
      <div class="card-body">
        <account-form
          :errors="errors"
          v-model="userInput"
          :isEdit="false"
        />
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="create">
        확인
      </button>
      &nbsp;
      <button type="button" class="btn btn-default btn-sm" @click="$router.back()">
        취소
      </button>
    </div>
  </div>
</template>
<script>
  import AccountForm from "@/components/account/manage/AccountForm";
  import {dispatch} from "@/core/dispatcher";
  import CreateAccountJob from "@/core/jobs/account/CreateAccountJob";

  export default {
    data() {
      return {
        userInput: {
          userid: '',
          email: '',
          phone_number: '',
          password: ''
        },
        errors: {}
      };
    },
    methods: {
      create() {
        dispatch(new CreateAccountJob({
          userid: this.userInput.userid,
          email: this.userInput.email,
          phone_number: this.userInput.phone_number,
          password: this.userInput.password
        }))
          .then(response => {
            this.$router.push({
              name: 'account.manage.index'
            });
          })
          .catch(err => {
            if (err.response.status === 422) {
              this.errors = err.response.data;
            }
          });
      }
    },
    components: {
      AccountForm
    }
  }
</script>
