<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-search"></i> 사용자 검색
      </div>
      <div class="card-body">
        <div class="clearfix">
          <div class="form-group pull-left mr-5">
            <label>인증여부</label><br />
            <button type="button" class="btn" :class="{'btn-primary': ! certified, 'btn-default': certified}" @click="certified = false">
              미인증
            </button>
            &nbsp;
            <button type="button" class="btn" :class="{'btn-primary': certified, 'btn-default': ! certified}" @click="certified = true">
              인증 완료
            </button>
          </div>
          <div class="form-group pull-left">
            <label for="userid">아이디</label><br />
            <input type="text" class="form-control" size="70" id="userid" v-model="userid" @keypress.enter="search">
          </div>
        </div>
      </div>
    </div>
    <div class="text-right mb-5">
      <button type="button" class="btn btn-primary" @click="search">
        검색
      </button>
    </div>
    <br />
  </div>
</template>
<script>
  export default {
    props: ['value'],
    data() {
      return {
        certified: false,
        userid: ''
      };
    },
    methods: {
      search() {
        this.$emit('search', {
          certified: this.certified,
          userid: this.userid
        });
      }
    }
  }
</script>
