<template>
  <div v-if="loaded">
    <h4 class="page-title">급여명세서</h4>

    <div class="alert alert-danger" v-if="payslip.state === 'user_rejected'">
      <strong>거절사유</strong> {{ payslip.user_reject_reason }}
    </div>

    <div>
      <button type="button" class="btn" :class="{'btn-primary': type === 'normal', 'btn-default': type !== 'normal'}" @click="changeType('normal')">
        일반근로자
      </button>
      &nbsp;
      <button type="button" class="btn" :class="{'btn-primary': type === 'business', 'btn-default': type !== 'business'}"  @click="changeType('business')">
        사업소득자
      </button>
      &nbsp;
      <button type="button" class="btn" :class="{'btn-primary': type === 'daily', 'btn-default': type !== 'daily'}" @click="changeType('daily')">
        일용근로자
      </button>
    </div>

    <br />

    <h3 v-if="employee !== null">사원명 : {{ employee.name }}</h3>
    <br />
    <div class="row">
      <div class="col-md-9">
        <div class="card" v-if="employee !== null">
          <div class="card-heading">
            <i class="fa fa-list"></i> 금액 입력
          </div>
          <div class="card-body">
            <div class="row">
              <div :class="etcList.length > 0 ? 'col-md-4' : 'col-md-6'">
                <div class="card">
                  <div class="card-heading">
                    <i class="fa fa-plus"></i> 급여항목
                  </div>
                  <div class="card-body">
                    <input-form
                      v-model="wages"
                      :employee="employee"
                      v-if="employee.employee_type !== '일용근로자'"
                    />
                    <daily-input-form
                      v-model="wages"
                      :employee="employee"
                      v-else
                    />
                  </div>
                </div>
              </div>
              <div :class="etcList.length > 0 ? 'col-md-4' : 'col-md-6'">
                <div class="card">
                  <div class="card-heading">
                    <i class="fa fa-minus"></i> 공제항목
                  </div>
                  <div class="card-body">
                    <input-form
                      v-model="excludes"
                      :finalPayment="finalPayment"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4" v-if="etcList.length > 0">
                <div class="card">
                  <div class="card-heading">
                    기타 항목
                  </div>
                  <div class="card-body">
                    <input-form
                        v-model="etcList"
                        show-total="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <button type="button" class="btn btn-primary btn-sm" @click="save">
            저장
          </button>
          &nbsp;
          <router-link
            class="btn btn-default btn-sm"
            :to="{name: 'payslip.index'}"
          >
            목록
          </router-link>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label>전체 급여 총계</label><br />
              <hr style="margin: 1px 0;" />
              <div>{{ totalWageAmount.format() }}</div>
            </div>
            <div class="form-group">
              <label>전체 공제 총계</label><br />
              <hr style="margin: 1px 0;" />
              <div>{{ totalExcludeAmount.format() }}</div>
            </div>
            <div class="form-group">
              <label>전체 차인지급액 총계</label><br />
              <hr style="margin: 1px 0;" />
              <div>{{ (totalWageAmount - totalExcludeAmount).format() }}</div>
            </div>
          </div>
        </div>

        <user-info
          @rollback="rollbackPayslip"
          @confirm="confirmPayslip"
          @notify-payslip-completed="notifyPayslipCompleted"
          @notify-tax-completed="notifyTaxCompleted"
        />
        <employee-list
          :type="type"
          @select="selectEmployee"
        />
        <upload-excel
          v-if="type !== 'daily'"
          @upload="uploadExcel"
        />
        <div class="card" v-if="type === 'normal'">
          <div class="card-heading">
            <i class="fa fa-file-upload"></i> 일반근로자 신고서 첨부
          </div>
          <div class="card-body">
            <upload-pdf-form
              type="employee"
              v-model="employeeFiles"
              @upload="upload"
            />
            <div>
              <div class="form-group">
                <a :href="payslip.employee_file1" class="btn btn-default btn-sm" v-if="payslip.employee_file1 !== null">
                  원천징수이행상황신고서
                </a>
              </div>
              <div class="form-group">
                <a :href="payslip.employee_file2" class="btn btn-default btn-sm" v-if="payslip.employee_file2 !== null">
                  원천징수 납부서
                </a>
              </div>
              <div class="form-group">
                <a :href="payslip.employee_file3" class="btn btn-default btn-sm" v-if="payslip.employee_file3 !== null">
                  원천징수 지방소득세 납부서
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="type === 'business'">
          <div class="card-heading">
            <i class="fa fa-file-upload"></i> 사업소득자 신고서 첨부
          </div>
          <div class="card-body">
            <upload-pdf-form
              type="business"
              v-model="businessFiles"
              @upload="upload"
            />
            <div>
              <div class="form-group">
                <a :href="payslip.business_file1" class="btn btn-default btn-sm" v-if="payslip.business_file1 !== null">
                  사업소득원천징수영수증
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="type === 'daily'">
          <div class="card-heading">
            <i class="fa fa-file-upload"></i> 일용근로자 신고서 납부
          </div>
          <div class="card-body">
            <upload-pdf-form
              type="daily"
              v-model="dailyEmployeeFiles"
              @upload="upload"
            />

            <div>
              <div class="form-group">
                <a :href="payslip.daily_file1" class="btn btn-default btn-sm" v-if="payslip.daily_file1 !== null">
                  근로내용확인신고서
                </a>
              </div>
              <div class="form-group">
                <a :href="payslip.daily_file2" class="btn btn-default btn-sm" v-if="payslip.daily_file2 !== null">
                  일용근로자 지급명세서
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters, mapState} from 'vuex';
  import {dispatch} from "@/core/dispatcher";
  import GetEmployeesJob from "@/core/jobs/payslip/GetEmployeesJob";
  import InputForm from "@/components/payslip/index/InputForm";
  import DailyInputForm from "@/components/payslip/index/DailyInputForm";
  import UserInfo from "@/components/payslip/index/UserInfo";
  import GetPayslipJob from "@/core/jobs/payslip/GetPayslipJob";
  import EmployeeList from "@/components/payslip/index/EmployeeList";
  import InputPropertyJob from "@/core/jobs/payslip/InputPropertyJob";
  import AdminConfirmJob from "@/core/jobs/payslip/AdminConfirmJob";
  import UploadPdfForm from "@/components/payslip/index/UploadPdfForm";
  import UploadFileJob from "@/core/jobs/payslip/UploadFileJob";
  import UploadExcel from "@/components/payslip/index/UploadExcel";
  import UploadExcelJob from "@/core/jobs/excel/UploadExcelJob";
  import RollbackPayslipJob from "@/core/jobs/payslip/RollbackPayslipJob";
  import NotifyJob from "@/core/jobs/payslip/NotifyJob";

  export default {
    data() {
      return {
        employeeFiles: {
          file1: null,
          file2: null,
          file3: null
        },
        businessFiles: {
          file1: null,
          file2: null,
          file3: null
        },
        dailyEmployeeFiles: {
          file1: null,
          file2: null,
          file3: null
        },
        wages: [],
        excludes: [],
        etcList: [],
        loaded: false,
        date: {
          year: '',
          month: ''
        },
        userId: ''
      };
    },
    computed: {
      ...mapState('payslip', {
        user: state => state.selectedUser,
        payslip: state => state.payslip,
        type: state => state.payslipType
      }),
      ...mapGetters('payslip', {
        employee: 'currentEmployee',
        employees: 'employees'
      }),
      finalPayment() {
        return this.wages.filter(wage => wage.id !== 1000 && wage.id !== 1001).reduce((a, b) => a + parseInt(b.value), 0) -
                this.excludes.reduce((a, b) => a + parseInt(b.value), 0);
      },
      totalWageAmount() {
        return this.wages.filter(v => (v.id !== 1000 && v.id !== 1001)).map(wage => {
          let amount = 0;

          this.employees.forEach(employee => {
            const v = employee.payslip_values.filter(v => v.base_property_id === wage.id);

            if (v.length > 0) {
              amount += v[0].value;
            }
          });

          return amount;
        }).reduce((a, b) => a + b, 0);
      },
      totalExcludeAmount() {
        return this.excludes.map(wage => {
          let amount = 0;

          this.employees.forEach(employee => {
            const v = employee.payslip_values.filter(v => v.base_property_id === wage.id);

            if (v.length > 0) {
              amount += v[0].value;
            }
          });

          return amount;
        }).reduce((a, b) => a + b, 0);
      }
    },
    methods: {
      changeType(type) {
        this.$store.commit('payslip/changePayslipType', type);

        this.$nextTick(() => {
          if (this.employees.length > 0) {
            this.selectEmployee(this.employees[0].id);
          }
        });
      },
      notifyPayslipCompleted() {
        if (!confirm('알림 메시지를 전송하시겠습니까?')) {
          return;
        }

        dispatch(new NotifyJob(this.userId,
          this.$route.query.year,
          this.$route.query.month,
          'payslip'
        )).then(() => {
          alert('알림 메시지를 전송하였습니다.');
        });
      },
      notifyTaxCompleted() {
        if (!confirm('알림 메시지를 전송하시겠습니까?')) {
          return;
        }

        dispatch(new NotifyJob(this.userId,
          this.$route.query.year,
          this.$route.query.month,
          'tax'
        )).then(() => {
          alert('알림 메시지를 전송하였습니다.');
        });
      },
      upload(type, files) {
        dispatch(new UploadFileJob(
          this.payslip.id,
          type,
          files.file1,
          files.file2,
          files.file3
        )).then(() => {
          dispatch(new GetPayslipJob(this.userId, this.year, this.month));
        });
      },
      rollbackPayslip() {
        if (! confirm('사용자 입력 가능 상태로 되돌리겠습니까?')) {
          return;
        }

        dispatch(new RollbackPayslipJob(this.userId, this.year, this.month)).then(() => {
          dispatch(new GetPayslipJob(this.userId, this.year, this.month));
        });
      },
      confirmPayslip() {
        if (! confirm('금액을 확정하시겠습니까?')) {
          return;
        }

        dispatch(
          new AdminConfirmJob(
            this.user.id,
            this.$route.query.year,
            this.$route.query.month
          )
        );
      },
      save() {
        dispatch(new InputPropertyJob(
          this.employee.id,
          this.$route.query.year,
          this.$route.query.month,
          [
            ...this.wages,
            ...this.excludes,
            ...this.etcList
          ]
        )).then(() => {
          alert('입력 완료');
        });
      },
      updateExcludeProperties() {
        this.excludes = [
          ...this.payslip.excludes
        ];

        this.excludes.forEach(property => {
          let value = 0;
          let comment = '';

          if (this.payslip !== null) {
            const values = this.employee.payslip_values.filter(v => v.base_property_id === property.id);

            if (values.length > 0) {
              value = values[0].value;
              comment = values[0].comment;
            }
          }

          property.comment = comment;
          property.value = value;
        });
      },
      updateEtcListProperties() {
        if (this.employee.employee_type === '일반근로자') {
          this.etcList = [
            {
              id: 1003,
              name: '근로일수',
              value: 0
            },
            {
              id: 1004,
              name: '총근로시간수',
              value: 0,
              comment: ''
            },
            {
              id: 1005,
              name: '연장근로시간수',
              value: 0,
              comment: ''
            },
            {
              id: 1006,
              name: '야간근로시간수',
              value: 0,
              comment: ''
            },
            {
              id: 1007,
              name: '휴일근로시간수',
              value: 0,
              comment: ''
            },
          ];

          this.etcList.forEach(property => {
            let value = 0;
            let comment = '';

            if (this.payslip !== null) {
              const values = this.employee.payslip_values.filter(v => v.base_property_id === property.id);

              if (values.length > 0) {
                value = values[0].value;
                comment = values[0].comment;
              }
            }

            property.value = value;
            property.comment = comment;
          });
        } else {
          this.etcList = [];
        }
      },
      updateWageProperties() {
        if (this.employee.employee_type === '일용근로자') {
          this.wages = [
            {
              id: 1000,
              name: '근로일수',
              value: 0,
              comment: ''
            },
            {
              id: 1001,
              name: '일평균 근로시간',
              value: 0,
              comment: ''
            },
            {
              id: 1002,
              name: '임금총액',
              value: 0,
              comment: ''
            }
          ];
        } else if (this.employee.employee_type === '사업소득자') {
          this.wages = [
            {
              id: 1,
              name: '지급액',
              value: 0
            }
          ];
        } else {
          this.wages = [
            {
              id: 1,
              name: '기본급',
              value: 0
            },
            ...this.payslip.wages
          ];

          if (this.employee.pay_type === '시급제') {
            this.wages.push({
              id: 2,
              name: '근무시간',
              value: 0
            });
          }
        }

        this.wages.forEach(property => {
          let value = 0;
          let comment = '';

          if (this.payslip !== null) {
            const values = this.employee.payslip_values.filter(v => v.base_property_id === property.id);

            if (values.length > 0) {
              value = values[0].value;
              comment = values[0].comment;
            }
          }

          property.value = value;
          property.comment = comment;
        });
      },
      selectEmployee(id) {
        this.$store.commit('payslip/updateUserIndex', id);

        this.$router.push({
          name: 'payslip.show',
          params: {
            id: this.$route.params.id
          },
          query: {
            year: this.$route.query.year,
            month: this.$route.query.month,
            id
          }
        }).then(() => {
          this.updateWageProperties();
          this.updateExcludeProperties();
          this.updateEtcListProperties();
        }).catch(() => {
        })
      },
      uploadExcel(data) {
        dispatch(new UploadExcelJob({
          employee_type: this.type,
          type: data.type,
          file: data.file,
          year: this.year,
          month: this.month,
          userId: this.userId
        })).then(() => {
          return Promise.all([
            dispatch(new GetPayslipJob(this.userId, this.year, this.month)),
            dispatch(new GetEmployeesJob(this.userId, this.year, this.month, 0))
          ]);
        }).then(() => {
          this.updateWageProperties();
          this.updateExcludeProperties();
        });
      }
    },
    created() {
      this.userId = this.$route.params.id;
      this.year = this.$route.query.year;
      this.month = this.$route.query.month;

      Promise.all([
        dispatch(new GetPayslipJob(this.userId, this.year, this.month)),
        dispatch(new GetEmployeesJob(this.userId, this.year, this.month, 0))
      ]).then(() => {
        if (this.$route.query.id === undefined) {
          if (this.employees.length > 0) {
            this.$store.commit('payslip/updateUserIndex', this.employees[0].id);
          } else {
            this.loaded = true;
            return;
          }
        } else {
          this.$store.commit('payslip/updateUserIndex', this.$route.query.id);
        }

        this.$nextTick(() => {
          this.updateWageProperties();
          this.updateExcludeProperties();
          this.updateEtcListProperties();
          this.loaded = true;
        });
      })
    },
    components: {
      InputForm,
      UserInfo,
      EmployeeList,
      UploadPdfForm,
      DailyInputForm,
      UploadExcel
    }
  }
</script>
