<template>
  <div>
    <h4 class="page-title">서식모음 생성</h4>

    <create-form />
  </div>
</template>
<script>
  import CreateForm from "@/components/announcement/notice/CreateTemplateForm";

  export default {
    components: {
      CreateForm
    }
  }
</script>
