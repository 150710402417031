import {DispatchContext, Job} from "@/core/types";
import * as fileApi from '@/core/api/file';

export default class DestroyImageJob implements Job {
  private id: number;

  constructor(id: number) {
    this.id = id;
  }

  async run(context: DispatchContext): Promise<any> {
    await fileApi.destroyImage(this.id);
  }
}
