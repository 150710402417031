<template>
  <div class="input-file clearfix">
    <input type="file" :id="id" @change="changed">

    <div class="input-group form-group-sm">
      <input type="text" class="form-control" readonly :value="selectedFileName">
      <span class="input-group-btn">
        <label :for="id" class="btn btn-default btn-sm pull-right">
          파일 선택
        </label>
      </span>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['id', 'value'],
    data() {
      return {
      };
    },
    computed: {
      selectedFileName() {
        if (this.value === null) {
          return '';
        }

        return this.value.name;
      }
    },
    methods: {
      changed(ev) {
        if (ev.target.files.length === 0) {
          this.$emit('input', null);
          return;
        }

        this.$emit('input', ev.target.files[0]);
      }
    }
  }
</script>
