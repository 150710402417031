<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i :class="icon"></i> {{ label }}
      </div>
      <div class="card-body">
        <table class="table">
          <colgroup>
            <col width="*" />
            <col width="80" />
          </colgroup>
          <thead>
          <tr>
            <th>항목명</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="property in properties">
            <tr :key="`property_${property.id}`">
              <td>{{ property.name }}</td>
              <td>
                <button type="button" class="btn btn-default btn-sm" @click="destroy(property)">
                  삭제
                </button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <pagination
          :page="page"
          :total="total"
          :perPage="per_page"
          @move-page="movePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import LoadBasePropertyJob from "@/core/jobs/base-property/LoadBasePropertyJob";
  import DestroyBasePropertyJob from "@/core/jobs/base-property/DestroyBasePropertyJob";

  export default {
    props: ['type', 'icon', 'label', 'properties', 'page', 'total', 'per_page'],
    methods: {
      movePage(page) {
        dispatch(new LoadBasePropertyJob({
          type: this.type,
          page
        }));
      },
      destroy(property) {
        if (!confirm('정말 삭제하시겠습니까?')) {
          return;
        }

        dispatch(new DestroyBasePropertyJob([property.id], this.type));
      }
    }
  }
</script>
