<template>
  <div v-if="loaded">
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-megaphone"></i> 공지사항
      </div>
      <div class="card-body">
        <notice-form
          v-model="userInput"
          :errors="errors"
          :file="announcement.files"
          @destroy-attachment="destroyAttachment"
        />
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="update">
        수정
      </button>
      &nbsp;
      <button type="button" class="btn btn-default btn-sm" @click="$router.back()">
        뒤로
      </button>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import NoticeForm from "@/components/announcement/notice/NoticeForm";
  import {dispatch} from "@/core/dispatcher";
  import UpdateAnnouncementJob from "@/core/jobs/announcement/UpdateAnnouncementJob";
  import DestroyAttachmentJob from "@/core/jobs/announcement/DestroyAttachmentJob";
  import LoadAnnouncementJob from "@/core/jobs/announcement/LoadAnnouncementJob";
  import SelectAnnouncementJob from "@/core/jobs/announcement/SelectAnnouncementJob";

  export default {
    data() {
      return {
        loaded: false,
        userInput: {
          title: '',
          content: '',
          files: []
        },
        errors: {}
      }
    },
    computed: {
      ...mapState('announcement', {
        announcement: state => state.selectedAnnouncement,
        page: state => state.page
      })
    },
    methods: {
      destroyAttachment(file) {
        if (! confirm('정말 삭제하시겠습니까?')) {
          return;
        }

        dispatch(new DestroyAttachmentJob(file.id)).then(() => {
          return dispatch(new LoadAnnouncementJob(this.page, 'notice'));
        }).then(() => {
          dispatch(new SelectAnnouncementJob(parseInt(this.$route.params.id)));
        });
      },
      update() {
        dispatch(new UpdateAnnouncementJob(this.announcement.id, this.userInput.title, this.userInput.content, this.userInput.files)).then(() => {
          alert('수정 완료');
          this.$router.back();
        }).catch(err => {
          if (err.response.status === 422) {
            this.errors = err.response.data;
          }
        });
      }
    },
    mounted() {
      this.userInput.title = this.announcement.title;
      this.userInput.content = this.announcement.content;

      this.loaded = true;
    },
    components: {
      NoticeForm
    }
  }
</script>
