import * as http from './http';

export interface UploadExcelData {
  employee_type: string;
  type: string;
  year: string;
  month: string;
  userId: string;
  file: any;
}

export function uploadExcel(data: UploadExcelData) {
  let formData = new FormData();

  formData.append('employee_type', data.employee_type);
  formData.append('type', data.type);
  formData.append('year', data.year);
  formData.append('month', data.month);
  formData.append('user_id', data.userId);
  formData.append('file', data.file);

  return http.post('/api/v1/admin/excel-upload', formData);
}
