import Page from '@/views/admin/announcement/Page.vue';

import NoticeIndexView from '@/views/admin/announcement/notice/Index.vue';
import NoticeCreateView from '@/views/admin/announcement/notice/Create.vue';
import NoticeEditView from '@/views/admin/announcement/notice/Edit.vue';

import TemplateIndexView from '@/views/admin/announcement/template/Index.vue';
import TemplateCreateView from '@/views/admin/announcement/template/Create.vue';
import TemplateEditView from '@/views/admin/announcement/template/Edit.vue';

import KnowhowIndexView from '@/views/admin/announcement/knowhow/Index.vue';
import KnowhowCreateView from '@/views/admin/announcement/knowhow/Create.vue';
import KnowhowEditView from '@/views/admin/announcement/knowhow/Edit.vue';

import CSCenterIndexView from '@/views/admin/announcement/cscenter/Index.vue';

export default [
  {
    path: 'announcement',
    component: Page,
    children: [
      {
        path: 'template',
        component: TemplateIndexView,
        name: 'announcement.template.index'
      },
      {
        path: 'template/create',
        component: TemplateCreateView,
        name: 'announcement.template.create'
      },
      {
        path: 'template/:id/edit',
        component: TemplateEditView,
        name: 'announcement.template.edit'
      },
      {
        path: 'notice',
        component: NoticeIndexView,
        name: 'announcement.notice.index'
      },
      {
        path: 'notice/create',
        component: NoticeCreateView,
        name: 'announcement.notice.create'
      },
      {
        path: 'notice/:id/edit',
        component: NoticeEditView,
        name: 'announcement.notice.edit'
      },
      {
        path: 'knowhow',
        component: KnowhowIndexView,
        name: 'announcement.knowhow.index'
      },
      {
        path: 'knowhow/create',
        component: KnowhowCreateView,
        name: 'announcement.knowhow.create'
      },
      {
        path: 'knowhow/:id/edit',
        component: KnowhowEditView,
        name: 'announcement.knowhow.edit'
      },
      {
        path: 'cscenter',
        component: CSCenterIndexView,
        name: 'announcement.cscenter.index'
      }
    ]
  }
]
