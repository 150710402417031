<template>
  <div>
    <h4 class="page-title">세무지식 생성</h4>

    <create-knowhow-form />
  </div>
</template>
<script>
  import CreateKnowhowForm from "@/components/announcement/notice/CreateKnowhowForm";

  export default {
    components: {
      CreateKnowhowForm
    }
  }
</script>
