<template>
  <div>
    <h4 class="page-title">사원 목록</h4>

    <employee-list />
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import LoadEmployeesJob from "@/core/jobs/user/LoadEmployeesJob";
  import EmployeeList from "@/components/user/manage/employee/EmployeeList";

  export default {
    mounted() {
      dispatch(new LoadEmployeesJob(this.$route.params.id, 0));
    },
    components: {
      EmployeeList
    }
  }
</script>
