<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user-sheld"></i> 계정 정보
      </div>
      <div class="card-body">
        <account-form
          v-model="userInput"
          :errors="errors"
          :isEdit="true"
        />
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="update">
        확인
      </button>
      &nbsp;
      <button type="button" class="btn btn-default btn-sm" @click="$router.back()">
        취소
      </button>
    </div>
  </div>
</template>
<script>
  import AccountForm from "@/components/account/manage/AccountForm";
  import {dispatch} from "@/core/dispatcher";
  import SelectAccountJob from "@/core/jobs/account/SelectAccountJob";
  import UpdateAccountJob from "@/core/jobs/account/UpdateAccountJob";

  export default {
    data() {
      return {
        userInput: {
          userid: '',
          email: '',
          password: '',
          phone_number: ''
        },
        errors: {}
      };
    },
    methods: {
      update() {
        dispatch(new UpdateAccountJob(
          this.$route.params.id,
          {
            userid: this.userInput.userid,
            email: this.userInput.email,
            password: this.userInput.password,
            phone_number: this.userInput.phone_number
          }
        )).then(response => {
          this.$router.push({
            name: 'account.manage.index'
          });
        }).catch(err => {
          if (err.response.status === 422) {
            this.errors = err.response.data;
          }
        });
      }
    },
    mounted() {
      dispatch(new SelectAccountJob(parseInt(this.$route.params.id))).then(response => {
        this.userInput = {
          userid: response.userid,
          email: response.email,
          password: '',
          phone_number: response.phone_number
        };
      });
    },
    components: {
      AccountForm
    }
  }
</script>
