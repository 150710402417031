<template>
  <div>
    <table class="table table-bordered">
      <tbody>
      <tr>
        <th>사원코드</th>
        <td>{{ employee.code }}</td>
      </tr>
      <tr>
        <th>이름</th>
        <td>{{ employee.name }}</td>
      </tr>
      <tr>
        <th>생년월일</th>
        <td>{{ employee.birthday }}</td>
      </tr>
      <tr>
        <th>직급</th>
        <td>{{ employee.position }}</td>
      </tr>
      <tr>
        <th>부서</th>
        <td>{{ employee.department }}</td>
      </tr>
      <tr>
        <th>입사일</th>
        <td>{{ employee.in_date }}</td>
      </tr>
      <tr>
        <th>퇴사일</th>
        <td>{{ employee.out_date }}</td>
      </tr>
      <tr v-if="employee.employee_type === '일반근로자'">
        <th>퇴사사유</th>
        <td>{{ employee.out_reason }}</td>
      </tr>
      <tr>
        <th>근무타입</th>
        <td>{{ employee.employee_type }}</td>
      </tr>
      <tr>
        <th>급여타입</th>
        <td>{{ employee.pay_type }}</td>
      </tr>
      <tr>
        <th>주민등록번호</th>
        <td>{{ employee.jumin }}</td>
      </tr>
      <tr>
        <th>퇴직소득원천징수영수증</th>
        <td>
          <v-input-file
            :id="`out_file_${employee.user_id}_${employee.id}`"
            :value="value.out"
            @input="update($event, 'out')"
          />
        </td>
      </tr>
      <tr>
        <th>상실신고서</th>
        <td>
          <v-input-file
            :id="`out_file2_${employee.user_id}_${employee.id}`"
            :value="value.out2"
            @input="update($event, 'out2')"
          />
        </td>
      </tr>
      <tr>
        <th>
          퇴직금
          <div class="badge badge-danger" style="font-weight: lighter; background: #B61D22;" v-if="employee.severance_pay_request">* 계산요청</div>
        </th>
        <td>
          <v-input
            type="number"
            :value="value.severance_pay"
            @input="updateValue"
            ref="severance_pay"
          />
        </td>
      </tr>
      <tr>
        <th>소득세</th>
        <td>
          <v-input
            type="number"
            :value="value.income_tax"
            @input="updateValue"
            ref="income_tax"
          />
        </td>
      </tr>
      <tr>
        <th>주민세</th>
        <td>
          <v-input
            type="number"
            :value="value.jumin_tax"
            @input="updateValue"
            ref="jumin_tax"
          />
        </td>
      </tr>
      <tr>
        <th>기타공제</th>
        <td>
          <v-input
            type="number"
            :value="value.etc_exclude"
            @input="updateValue"
            ref="etc_exclude"
          />
        </td>
      </tr>
      <tr>
        <th>차인지급액</th>
        <td>
          <v-input
            type="number"
            :value="value.final_payment"
            @input="updateValue"
            ref="final_payment"
          />
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  export default {
    props: ['employee', 'value'],
    data() {
      return {
        out_file: null,
        out_file2: null
      };
    },
    methods: {
      update(v, type) {
        if (type === 'out') {
          this.out_file = v;
        } else if (type === 'out2') {
          this.out_file2 = v;
        }

        this.updateValue();
      },
      updateValue() {
        this.$emit('input', {
          out: this.out_file,
          out2: this.out_file2,
          income_tax: this.$refs.income_tax.$refs.input.value,
          jumin_tax: this.$refs.jumin_tax.$refs.input.value,
          etc_exclude: this.$refs.etc_exclude.$refs.input.value,
          final_payment: this.$refs.final_payment.$refs.input.value,
          severance_pay: this.$refs.severance_pay.$refs.input.value
        });
      }
    }
  }
</script>
