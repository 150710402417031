import {DispatchContext, Job} from "@/core/types";

import * as payslipApi from '@/core/api/payslip';

export default class NotifyJob implements Job {
  private userId: number;
  private year: string;
  private month: string;
  private type: string;

  constructor(userId: number, year: string, month: string, type: string) {
    this.userId = userId;
    this.year = year;
    this.month = month;
    this.type = type;
  }

  async run(context: DispatchContext): Promise<any> {
    await payslipApi.notify(this.userId, this.year, this.month, this.type);
  }
}
