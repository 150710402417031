import {Admin} from "@/core/types/model";

export default {
  namespaced: true,
  state: {
    admin: null
  },
  mutations: {
    setAdmin(state: any, admin: Admin | null) {
      if (admin === null) {
        state.admin = null;
      } else {
        state.admin = {
          ...admin
        };
      }
    }
  }
}
