import * as http from './http';

export interface UploadImageData {
  file: any;
}

export function uploadImage(data: UploadImageData) {
  return http.post('/api/v1/admin/image', data);
}

export function getImages(page: number) {
  return http.get(`/api/v1/admin/image?page=${page}`);
}
