<template>
  <div>
    <h4 class="page-title">자동알림 로그</h4>

    <log-list />
  </div>
</template>
<script>
  import LogList from '@/components/log/alarm-log/LogList.vue';

  export default {
    components: {
      LogList
    }
  }
</script>
