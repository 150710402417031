import {DispatchContext, Job} from "@/core/types";

export default class SelectEmployeeJob implements Job {
  private readonly id: number;

  constructor(id: number) {
    this.id = id;
  }

  async run(context: DispatchContext): Promise<any> {
    const employees = context.store.state.user.employee.employees.filter((e: any) => e.id === this.id);

    context.store.commit('user/setSelectedEmployee', employees[0]);
  }
}
