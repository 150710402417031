<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-plus-circle"></i> 항목 추가
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>항목 타입</label><br />
              <button
                type="button"
                class="btn"
                :class="{'btn-primary': type === 'wage', 'btn-default': type !== 'wage'}"
                @click="type = 'wage'">
                급여 항목
              </button>
              &nbsp;
              <button
                type="button"
                class="btn"
                :class="{'btn-primary': type === 'exclude', 'btn-default': type !== 'exclude'}"
                @click="type = 'exclude'">
                공제 항목
              </button>
            </div>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <label for="name">항목명</label>
              <input type="text" class="form-control" id="name" v-model="name">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="create">
        추가
      </button>
    </div>
  </div>
</template>
<script>
  import CreateBasePropertyJob from "@/core/jobs/base-property/CreateBasePropertyJob";
  import {dispatch} from "@/core/dispatcher";

  export default {
    data() {
      return {
        type: 'wage',
        name: ''
      }
    },
    methods: {
      create() {
        dispatch(new CreateBasePropertyJob({
          type: this.type,
          name: this.name
        })).then(() => {
          this.name = '';
        });
      }
    }
  }
</script>
