<template>
  <div>
    <div class="form-group">
      <label for="title">제목</label>
      <v-input
        type="text"
        id="title"
        :value="value.title"
        :errors="errors.title"
        ref="title"
        @input="update"
      />
    </div>
    <div class="form-group">
      <label for="files_1">첨부파일</label>
      <div class="row">
        <div class="col-md-3">
          <template v-for="(file, index) in files">
            <div class="form-group" :key="`file_${index}`">
              <v-input-file
                :id="`files_${index}`"
                v-model="file.file"
                @input="update"
              />
            </div>
          </template>
        </div>
        <div class="col-md-3">
          <button type="button" class="btn btn-default btn-sm" @click="addFileCount">
            +
          </button>
        </div>
      </div>
      <div v-if="file !== undefined && file.length > 0">
        <template v-for="f in file">
          <div class="form-group">
            <a :href="f.url" target="_blank" class="btn btn-default btn-sm">
              <i class="fal fa-download"></i> {{ f.name }}
            </a>
            &nbsp;
            <button type="button" class="btn btn-link btn-sm" @click="destroyAttachment(f)">
              삭제
            </button>
          </div>
        </template>
      </div>
    </div>
    <div class="form-group">
      <label for="content">내용</label>
      <div ref="summernote" v-html="value.content"></div>
    </div>
  </div>
</template>
<script>
  import {getImageButton} from '@/plugins/summernote-0.8.12-dist/buttons/image';
  import {dispatch} from "@/core/dispatcher";
  import DestroyAttachmentJob from "@/core/jobs/announcement/DestroyAttachmentJob";

  export default {
    props: ['value', 'errors', 'file'],
    data() {
      return {
        files: [
          {
            file: null
          }
        ]
      };
    },
    methods: {
      destroyAttachment(file) {
        this.$emit('destroy-attachment', file);
      },
      addFileCount() {
        this.files.push({
          file: null
        });
      },
      update() {
        this.$nextTick(() => {
          this.$emit('input', {
            title: this.$refs.title.$refs.input.value,
            content: $(this.$refs.summernote).summernote('code'),
            files: this.files.filter(file => file.file !== null).map(file => file.file),
          });
        });
      }
    },
    mounted() {
      $(this.$refs.summernote).summernote({
        height: 500,
        lang: 'ko-KR',
        toolbar: [
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['height', ['height']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['mybutton', ['image']]
        ],
        buttons: {
          image: getImageButton((url) => {
            $(this.$refs.summernote).summernote('insertImage', url);
          })
        },
        callbacks: {
          onChange: () => {
            this.update();
          }
        }
      });
    }
  }
</script>
