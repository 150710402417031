import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

import auth from './auth';
import user from './user';
import account from './account';
import baseProperty from './base-property';
import employeeManage from './employee-manage';
import payslip from './payslip';
import announcement from "./announcement";
import imageModal from './image-modal';
import alarmLog from './alarm-log';

export default new Vuex.Store({
  state: {
    validationErrors: {},
    isLoading: true
  },
  mutations: {
    setValidationErrors(state, errors) {
      state.validationErrors = errors;
    },
    setIsLoading(state, v) {
      state.isLoading = v;
    }
  },
  actions: {
  },
  modules: {
    auth,
    user,
    account,
    baseProperty,
    employeeManage,
    payslip,
    announcement,
    imageModal,
    alarmLog
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
});
