import {DispatchContext, Job} from "@/core/types";
import {getEmployees} from "@/core/api/payslip";

export default class GetEmployeesJob implements Job {
  private userId: number;
  private year: string;
  private month: string;
  private page: number;

  constructor(userId: number, year: string, month: string, page: number) {
    this.userId = userId;
    this.year = year;
    this.month = month;
    this.page = page;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getEmployees(this.userId, this.year, this.month, this.page);

    context.store.commit('payslip/setEmployees', response.data.data);
  }
}
