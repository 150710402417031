import {DispatchContext, Job} from "@/core/types";

import * as accountApi from '@/core/api/account';

export default class DestroyAccountJob implements Job {
  private readonly idList: Array<number>;

  constructor(idList: Array<number>) {
    this.idList = idList;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await accountApi.destroyAccount(this.idList);
  }
}
