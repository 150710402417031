import {PaginateData} from "@/core/types";
import {User} from "@/core/types/model";

export default {
  namespaced: true,
  state: {
    selectedUser: null,
    users: [],
    page: 0,
    per_page: 0,
    total: 0,
    employee: {
      employees: [],
      page: 0,
      per_page: 0,
      total: 0,
      selectedEmployee: null
    }
  },
  mutations: {
    setSelectedEmployee(state: any, data: any) {
      if (data === null) {
        state.employee.selectedEmployee = null;
      } else {
        state.employee.selectedEmployee = {
          ...data
        };
      }
    },
    setEmployees(state: any, data: PaginateData) {
      state.employee.employees = [
        ...data.data
      ];
      state.employee.page = data.page;
      state.employee.per_page = data.per_page;
      state.employee.total = data.total;
    },
    setUser(state: any, data: PaginateData) {
      state.users = [
        ...data.data
      ];
      state.page = data.page;
      state.per_page = data.per_page;
      state.total = data.total;
    },
    setSelectedUser(state: any, data: User) {
      state.selectedUser = {
        ...data
      };
    },
    updateUser(state: any, { id, data }: any) {
      state.users.forEach((user: any) => {
        if (user.id === id) {
          for (let key in user) {
            user[key] = data[key];
          }
        }
      });

      if (state.selectedUser !== null && state.selectedUser.id === id) {
        state.selectedUser = {
          ...data
        };
      }
    }
  }
}
