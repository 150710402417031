<template>
  <div>
    <div class="form-group">
      <label for="userid">아이디</label>
      <template v-if="isEdit">
        <input type="text" id="userid" :value="value.userid" readonly class="form-control">
      </template>
      <template v-else>
        <v-input type="text" ref="userid" id="userid" :errors="errors.userid" :value="value.userid" @input="update" />
      </template>
    </div>
    <div class="form-group">
      <label for="email">이메일</label>
      <v-input type="email" ref="email" id="email" :errors="errors.email" :value="value.email" @input="update" />
    </div>
    <div class="form-group">
      <label for="phone_number">휴대전화번호</label>
      <v-input type="tel" ref="phone_number" id="phone_number" :errors="errors.phone_number" :value="value.phone_number" @input="update" />
    </div>
    <div class="form-group">
      <label for="password">비밀번호</label>
      <v-input type="text" ref="password" id="password" :errors="errors.password" :value="value.password" @input="update" />
    </div>
  </div>
</template>
<script>
  export default {
    props: ['value', 'errors', 'isEdit'],
    methods: {
      update() {
        this.$emit('input', {
          userid: this.isEdit ? this.value.userid : this.$refs.userid.$refs.input.value,
          email: this.$refs.email.$refs.input.value,
          phone_number: this.$refs.phone_number.$refs.input.value,
          password: this.$refs.password.$refs.input.value
        });
      }
    }
  }
</script>
