<template>
  <div>
    <side-bar
      :menus="menus"
    />
    <div class="page">
      <router-view
      />
    </div>
  </div>
</template>
<script>
  import SideBar from '@/components/layouts/SideBar';

  export default {
    data() {
      return {
        menus: [
          {
            icon :'fa fa-receipt',
            label : '금액 입력',
            routeName: 'payslip.index',
            super: false
          }
        ]
      }
    },
    components: {
      SideBar
    }
  }
</script>
