<template>
  <div>
    <h4 class="page-title">사원 추가</h4>

    <create-form />
  </div>
</template>
<script>
  import CreateForm from "@/components/user/manage/employee/CreateForm";

  export default {
    components: {
      CreateForm
    }
  }
</script>
