<template>
  <div>
    <div class="heading">
      <div class="logo">
        <img src="../../assets/logo/logo.png" alt="로고" width="200">
      </div>
      <h4>
        호연 급여관리 시스템 관리자
      </h4>
    </div>
    <div class="body">
      <div class="form-group">
        <label for="userid">아이디</label>
        <v-input
          type="text"
          id="userid"
          placeholder="아이디"
          v-model="userInput.userid"
          :errors="errors.userid"
          @keypress.enter="login"
        />
      </div>
      <div class="form-group">
        <label for="password">비밀번호</label>
        <v-input
          type="password"
          id="password"
          placeholder="비밀번호"
          v-model="userInput.password"
          :errors="errors.password"
          @keypress.enter="login"
        />
      </div>
    </div>
    <div class="footer">
      <button type="button" class="btn btn-lg btn-primary btn-block" @click="login">
        로그인
      </button>
    </div>
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import LoginJob from "@/core/jobs/auth/LoginJob";

  export default {
    data() {
      return {
        userInput: {
          userid: '',
          password: ''
        },
        errors: {}
      };
    },
    methods: {
      login() {
        dispatch(new LoginJob(this.userInput.userid, this.userInput.password))
          .then(() => {
            this.$router.push({
              name: 'user.index'
            });
          })
          .catch((err) => {
            console.log(err);
            alert('아이디와 비밀번호를 확인해주세요.');
          });
      }
    }
  }
</script>
