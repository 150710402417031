import {AlarmLogSearchData, getLogs} from "@/core/api/alarm-log";
import {DispatchContext, Job} from "@/core/types";

export default class LoadLogsJob implements Job {
  private page: number;
  private data: AlarmLogSearchData;

  constructor(page: number, data: AlarmLogSearchData) {
    this.page = page;
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getLogs(this.page, this.data);

    context.store.commit('alarmLog/setLogs', response.data.data);
  }
}
