<template>
  <header class="header">
    <div class="logo">
      <router-link to="/">
        <img src="../../assets/logo/logo.png" alt="로고" width="150">
      </router-link>
    </div>
    <ul class="header-menus">
      <li>
        <router-link
          :to="{name: 'user.index'}"
          :class="{active: currentPath.indexOf('/user') === 0}"
        >
          사용자 관리
        </router-link>
      </li>
      <li v-if="admin !== null && admin.is_super === true">
        <router-link
          :to="{name: 'base-property.index'}"
          :class="{active: currentPath.indexOf('/base-property') === 0}"
        >
          항목 관리
        </router-link>
      </li>
      <li>
        <router-link
          :to="{name: 'payslip.index'}"
          :class="{active: currentPath.indexOf('/payslip') === 0}"
        >
          금액 입력
        </router-link>
      </li>
      <li v-if="admin !== null && admin.is_super === true">
        <router-link
          :to="{name: 'account.manage.index'}"
          :class="{active: currentPath.indexOf('/account') === 0}"
        >
          관리자 계정 관리
        </router-link>
      </li>
      <li>
        <router-link
          :to="{name: 'announcement.notice.index'}"
          :class="{active: currentPath.indexOf('/announcement') === 0}"
        >
          공지사항 관리
        </router-link>
      </li>
      <li>
        <router-link
          :to="{name: 'my-page.account.index'}"
          :class="{active: currentPath.indexOf('/my-page') === 0}"
        >
          마이페이지
        </router-link>
      </li>
      <li>
        <router-link
          :to="{name: 'log.alarm.index'}"
          :class="{active: currentPath.indexOf('/log') === 0}"
        >
          로그
        </router-link>
      </li>
      <li>
        <a href="javascript:;" @click="logout">로그아웃</a>
      </li>
    </ul>
  </header>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import LogoutJob from "@/core/jobs/auth/LogoutJob";
  import {mapState} from 'vuex';

  export default {
    computed: {
      currentPath() {
        return this.$route.path;
      },
      ...mapState('auth', {
        admin: state => state.admin
      })
    },
    methods: {
      logout() {
        dispatch(new LogoutJob()).then(() => {
          this.$router.push({
            name: 'login'
          });
        });
      }
    }
  }
</script>
