<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-search"></i> 사용자 검색
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="userid">아이디</label><br />
          <input type="text" class="form-control" id="userid" v-model="userid">
        </div>
      </div>
    </div>
    <div class="text-right mb-5">
      <button type="button" class="btn btn-primary" @click="search">
        검색
      </button>
    </div>
    <br />
  </div>
</template>
<script>
  export default {
    props: ['value'],
    data() {
      return {
        userid: ''
      };
    },
    methods: {
      search() {
        this.$emit('search', {
          userid: this.userid
        });
      }
    }
  }
</script>
