<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-megaphone"></i> 세무지식
      </div>
      <div class="card-body">
        <notice-form
          v-model="userInput"
          :errors="errors"
        />
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="save">
        저장
      </button>
      &nbsp;
      <button type="button" class="btn btn-default btn-sm" @click="$router.back()">
        뒤로
      </button>
    </div>
  </div>
</template>
<script>
  import NoticeForm from "@/components/announcement/notice/NoticeForm";
  import {dispatch} from "@/core/dispatcher";
  import CreateAnnouncementJob from "@/core/jobs/announcement/CreateAnnouncementJob";

  export default {
    data() {
      return {
        userInput: {
          title: '',
          content: '',
          files: []
        },
        errors: {}
      }
    },
    methods: {
      save() {
        dispatch(new CreateAnnouncementJob(this.userInput.title, this.userInput.content, 'knowhow', this.userInput.files)).then(() => {
          alert('저장완료');
          this.$router.push({name: 'announcement.knowhow.index'});
        }).catch(err => {
          if (err.response.status === 422) {
            this.errors = err.response.data;
          }
        });
      }
    },
    components: {
      NoticeForm
    }
  }
</script>
