import * as http from './http';

export interface SearchData {
  adminId?: number | undefined,
  userid?: string,
  certified: boolean,
  page: number
}

export interface UpdateUserData {
  admin_id?: number,
  name: string,
  phone_number: string,
  email: string,
  company_number: string,
  pay_day_type: string,
  pay_day: number,
  password?: string,
  password_confirmation?: string,
  confirmed?: boolean
}

export function paginate(data: SearchData) {
  const params = [];

  if (data.adminId !== undefined) {
    params.push(`admin_id=${data.adminId}`);
  }

  if (data.userid !== undefined && data.userid !== '') {
    params.push(`userid=${data.userid}`);
  }

  params.push(`page=${data.page}`);
  params.push(`certified=${data.certified ? 1 : 0}`);

  return http.get(`/api/v1/admin/user?${params.join('&')}`);
}

export function update(id: number, data: UpdateUserData) {
  return http.put(`/api/v1/admin/user/${id}`, data);
}

export function confirm(id: number, data: UpdateUserData) {
  return http.post(`/api/v1/admin/user/${id}`, data);
}

export function destroy(id: number) {
  return http.destroy(`/api/v1/admin/user/${id}`);
}

export function updateUserAdmin(userId: number[], adminId: number) {
  return http.post('/api/v1/admin/user/update-user-admin', {
    userId,
    adminId
  });
}