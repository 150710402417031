<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-list"></i> 공지사항 목록
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <colgroup>
            <col width="80" />
            <col width="*" />
            <col width="120" />
            <col width="120" />
          </colgroup>
          <thead>
          <tr>
            <th></th>
            <th>제목</th>
            <th>생성일</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(announcement, index) in announcements">
            <tr>
              <td align="center">{{ total - (per_page * (page - 1)) - index }}</td>
              <td>{{ announcement.title }}</td>
              <td>{{ announcement.created_at }}</td>
              <td>
                <router-link
                  :to="{name: 'announcement.notice.edit', params: {id: announcement.id}}"
                  class="btn btn-default btn-sm"
                >
                  수정
                </router-link>
                &nbsp;
                <button type="button" class="btn btn-default btn-sm" @click="destroy(announcement)">
                  삭제
                </button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>

        <pagination
          :page="page"
          :per-page="per_page"
          :total="total"
          @move-page="movePage"
        />
      </div>
    </div>

    <div class="text-right">
      <router-link
        :to="{name: 'announcement.notice.create'}"
        class="btn btn-primary btn-sm"
      >
        생성
      </router-link>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import {dispatch} from "@/core/dispatcher";
  import LoadAnnouncementJob from "@/core/jobs/announcement/LoadAnnouncementJob";
  import DestroyAnnouncementJob from "@/core/jobs/announcement/DestroyAnnouncementJob";

  export default {
    computed: {
      ...mapState('announcement', {
        announcements: state => state.announcements,
        total: state => state.total,
        per_page: state => state.per_page,
        page: state => state.page
      })
    },
    methods: {
      movePage(page) {
        dispatch(new LoadAnnouncementJob(page, 'notice'));
      },
      destroy(announcement) {
        if (! confirm('정말 삭제하시겠습니까?')) {
          return;
        }

        dispatch(new DestroyAnnouncementJob(announcement.id)).then(() => {
          dispatch(new LoadAnnouncementJob(this.page, 'notice'));
        });
      }
    }
  }
</script>
