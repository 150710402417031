import {DispatchContext, Job} from "@/core/types";
import {createAnnouncement} from "@/core/api/announcement";

export default class CreateAnnouncementJob implements Job {
  private readonly title: string;
  private readonly content: string;
  private readonly type: string;
  private readonly files: Array<any>;

  constructor(title: string, content: string, type: string, files: Array<any>) {
    this.title = title;
    this.content = content;
    this.type = type;
    this.files = files;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await createAnnouncement({
      title: this.title,
      content: this.content,
      files: this.files,
      type: this.type
    });
  }
}
