<template>
  <div>
    <template v-if="type === 'business'">
      <div class="form-group">
        <label for="file1">사업소득원천징수영수증</label>
        <v-input-file
          :id="`${type}_file1`"
          :value="value.file1"
          @input="update($event, 'file1')"
        />
      </div>
    </template>
    <template v-if="type === 'daily'">
      <div class="form-group">
        <label for="file1">근로내용확인신고서</label>
        <v-input-file
          :id="`${type}_file1`"
          :value="value.file1"
          @input="update($event, 'file1')"
        />
      </div>
      <div class="form-group">
        <label for="file2">일용근로자 지급명세서</label>
        <v-input-file
          :id="`${type}_file2`"
          :value="value.file2"
          @input="update($event, 'file2')"
        />
      </div>
    </template>
    <template v-if="type === 'employee'">
      <div class="form-group">
        <label for="file1">원천징수이행상황신고서</label>
        <v-input-file
          :id="`${type}_file1`"
          :value="value.file1"
          @input="update($event, 'file1')"
        />
      </div>
      <div class="form-group">
        <label for="file2">원천징수 납부서</label>
        <v-input-file
          :id="`${type}_file2`"
          :value="value.file2"
          @input="update($event, 'file2')"
        />
      </div>
      <div class="form-group">
        <label for="file3">원천징수 지방소득세 납부서</label>
        <v-input-file
          :id="`${type}_file3`"
          :value="value.file3"
          @input="update($event, 'file3')"
        />
      </div>
    </template>
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="upload">
        업로드
      </button>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['value', 'type'],
    methods: {
      update(ev, type) {
        this.$emit('input', {
          ...this.value,
          [type]: ev
        })
      },
      upload() {
        this.$emit(
          'upload',
          this.type,
          {
            file1: this.value.file1,
            file2: this.value.file2,
            file3: this.value.file3
          }
        );

        this.$emit('input', {
          file1: null,
          file2: null,
          file3: null
        });
      }
    }
  }
</script>
