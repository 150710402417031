<template>
  <div class="side-bar">
    <ul class="side-menus">
      <template v-for="menu in menus">
        <li v-if="menu.super === false || (menu.super === true && admin !== null && admin.is_super === true)">
          <router-link :to="{name: menu.routeName}" active-class="active">
            <i :class="menu.icon"></i>
            {{ menu.label }}
          </router-link>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
  import {mapState} from 'vuex';

  export default {
    props: ['menus'],
    computed: {
      ...mapState('auth', {
        admin: state => state.admin
      })
    }
  }
</script>
