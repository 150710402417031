import {DispatchContext, Job} from "@/core/types";
import {update, UpdateAccountData} from "@/core/api/auth";

export default class UpdateAccountJob implements Job {
  private readonly data: UpdateAccountData;

  constructor(data: UpdateAccountData) {
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await update(this.data);

    context.store.commit('auth/setAdmin', response.data.data);
  }
}
