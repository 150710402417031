import * as http from './http';

export function get(property: string) {
  return http.get(`/api/v1/admin/config?property=${property}`);
}

export function addConfig(property: string, value: string) {
  return http.post('/api/v1/admin/config', {
    property,
    value
  });
}

export function updateConfig(id: number, value: string) {
  return http.post(`/api/v1/admin/config/${id}`, {
    value
  });
}

export function removeConfig(id: number) {
  return http.destroy(`/api/v1/admin/config/${id}`);
}
