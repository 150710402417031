<template>
  <div>
    <h4 class="page-title">사용자 관리</h4>

    <user-list />
  </div>
</template>
<script>
  import UserList from '@/components/user/manage/index/UserList.vue';

  import {dispatch} from "@/core/dispatcher";
  import LoadUserListJob from "@/core/jobs/user/LoadUserListJob";
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('user', {
        page: state => state.page
      })
    },
    mounted() {
      dispatch(new LoadUserListJob({
        page: 0
      }));
    },
    components: {
      UserList
    }
  }
</script>
