import {DispatchContext, Job} from "@/core/types";
import {inputProperty} from "@/core/api/payslip";
import {dispatch} from "@/core/dispatcher";
import GetPayslipJob from "@/core/jobs/payslip/GetPayslipJob";

export default class InputPropertyJob implements Job {
  private employeeId: string;
  private year: string;
  private month: string;
  private properties: Array<any>;

  constructor(employeeId: string, year: string, month: string, properties: Array<any>) {
    this.employeeId = employeeId;
    this.year = year;
    this.month = month;
    this.properties = properties;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await inputProperty({
      employee_id: this.employeeId,
      year: this.year,
      month: this.month,
      properties: this.properties
    });
    return dispatch(
      new GetPayslipJob(
        context.store.state.payslip.selectedUser.id,
        this.year,
        this.month
      )
    )
  }
}
