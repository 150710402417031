<template>
  <div>
    <employee-form
      v-model="userInput"
      :errors="errors"
    />

    <div class="card">
      <div class="card-heading">
        <i class="fa fa-hands-helping"></i> 피부양자
      </div>
      <div class="card-body">
        <dependent-form
          v-model="dependents"
        />
      </div>
    </div>

    <div class="card" v-if="employee.dependent_file !== null">
      <div class="card-heading">
        <i class="fa fa-file-download"></i> 가족관계증명서
      </div>
      <div class="card-body">
        <a :href="employee.dependent_file" target="_blank" class="btn btn-default">다운로드</a>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-heading">
            <i class="fa fa-plus"></i> 입사정보
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="in_date">입사일</label>
                  <v-date-picker
                    id="in_date"
                    :value="in_files.in_date"
                    @input="updateInFiles($event, 'in_date')"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="in_file">취득 신고서</label>
                  <v-input-file
                    id="in_file"
                    :value="in_files.file"
                    @input="updateInFiles($event, 'file')"
                  />
                </div>
                <a v-if="employee.regist_file !== null" :href="employee.regist_file" target="_blank" type="button" class="btn btn-default">
                  다운로드
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-heading">
            <i class="fa fa-minus"> 퇴사 정보</i>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="out_date">퇴사일</label>
                  <v-date-picker
                    id="out_date"
                    :value="leave_files.out_date"
                    @input="updateLeaveFiles($event, 'out_date')"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="out">퇴직소득원천징수영수증</label>
                  <v-input-file
                    id="out"
                    :value="leave_files.out"
                    @input="updateLeaveFiles($event, 'out')"
                  />
                </div>

                <div class="form-group">
                  <a v-if="employee.out_file !== null" :href="employee.out_file" target="_blank" type="button" class="btn btn-default">
                    다운로드
                  </a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="out2">상실신고서</label>
                  <v-input-file
                    id="out2"
                    :value="leave_files.out2"
                    @input="updateLeaveFiles($event, 'out2')"
                  />
                </div>
                <div class="form-group">
                  <a v-if="employee.out_file2 !== null" :href="employee.out_file2" target="_blank" type="button" class="btn btn-default">
                    다운로드
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="severance_pay">퇴직금</label>
                  <v-input
                    type="number"
                    id="severance_pay"
                    :value="leave_files.severance_pay"
                    @input="updateLeaveFiles($event, 'severance_pay')"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="income_tax">소득세</label>
                  <v-input
                    type="number"
                    id="income_tax"
                    :value="leave_files.income_tax"
                    @input="updateLeaveFiles($event, 'income_tax')"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="jumin_tax">주민세</label>
                  <v-input
                    type="number"
                    id="jumin_tax"
                    :value="leave_files.jumin_tax"
                    @input="updateLeaveFiles($event, 'jumin_tax')"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="etc_exclude">기타공제</label>
                  <v-input
                    type="number"
                    id="etc_exclude"
                    :value="leave_files.etc_exclude"
                    @input="updateLeaveFiles($event, 'etc_exclude')"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="final_payment">차인지급액</label>
                  <v-input
                    type="number"
                    id="final_payment"
                    :value="leave_files.final_payment"
                    @input="updateLeaveFiles($event, 'final_payment')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="update">
        확인
      </button>
      &nbsp;
      <button type="button" class="btn btn-default btn-sm" @click="$router.back()">
        뒤로
      </button>
    </div>
  </div>
</template>
<script>
  import EmployeeForm from "./EmployeeForm";
  import {mapState} from 'vuex';
  import {dispatch} from "@/core/dispatcher";
  import UpdateEmployeeInfoJob from "@/core/jobs/user/UpdateEmployeeInfoJob";
  import DependentForm from "@/components/user/manage/employee/DependentForm";

  export default {
    data() {
      return {
        in_files: {
          in_date: '',
          file: null
        },
        leave_files: {
          out_date: '',
          out: null,
          out2: null,
          income_tax: 0,
          jumin_tax: 0,
          etc_exclude: 0,
          final_payment: 0,
          severance_pay: 0
        },
        dependents: [
        ],
        userInput: {
        },
        errors: {}
      };
    },
    watch: {
      'leave_files.severance_pay': function(v) {
        this.leave_files.final_payment =
          v -
          this.leave_files.income_tax -
          this.leave_files.jumin_tax -
          this.leave_files.etc_exclude;
      },
      'leave_files.income_tax': function(v) {
        this.leave_files.final_payment =
          this.leave_files.severance_pay -
          v -
          this.leave_files.jumin_tax -
          this.leave_files.etc_exclude;
      },
      'leave_files.jumin_tax': function(v) {
        this.leave_files.final_payment =
          this.leave_files.severance_pay -
          this.leave_files.income_tax -
          v -
          this.leave_files.etc_exclude;
      },
      'leave_files.etc_exclude': function(v) {
        this.leave_files.final_payment =
          this.leave_files.severance_pay -
          this.leave_files.income_tax -
          this.leave_files.jumin_tax -
          v;
      }
    },
    computed: {
      ...mapState('user', {
        employee: state => state.employee.selectedEmployee
      })
    },
    methods: {
      updateInFiles(v, type) {
        this.in_files[type] = v;
      },
      updateLeaveFiles(v, type) {
        this.leave_files[type] = v;
      },
      update() {
        let data = {
          ...this.userInput,
          dependents: [
            ...this.dependents
          ],
          jumin_tax: this.leave_files.jumin_tax,
          income_tax: this.leave_files.income_tax,
          etc_exclude: this.leave_files.etc_exclude,
          final_payment: this.leave_files.final_payment,
          severance_pay: this.leave_files.severance_pay
        };

        if (this.in_files.in_date !== '') {
          data.in_date = this.in_files.in_date;
        }

        data.out_date = this.leave_files.out_date;

        if (this.in_files.file !== null) {
          data['regist_file'] = this.in_files.file;
        }

        if (this.leave_files.out !== null) {
          data['out_file'] = this.leave_files.out;
        }

        if (this.leave_files.out2 !== null) {
          data['out_file2'] = this.leave_files.out2;
        }

        dispatch(new UpdateEmployeeInfoJob(parseInt(this.$route.params.id), data))
          .then(() => {
            alert('수정 완료');
            this.in_files.file = null;
            this.leave_files.out = null;
            this.leave_files.out2 = null;
            this.errors = {};
          })
          .catch(err => {
            if (err.response.status === 422) {
              this.errors = err.response.data;
            }
          });
      }
    },
    mounted() {
      this.userInput = {
        code: this.employee.code,
        name: this.employee.name,
        position: this.employee.position,
        department: this.employee.department,
        in_date: this.employee.in_date,
        out_date: this.employee.out_date,
        employee_type: this.employee.employee_type,
        pay_type: this.employee.pay_type,
        jumin: this.employee.jumin,
        income: this.employee.income,
        working_hour: this.employee.working_hour,
        role: this.employee.role,
        is_contract: this.employee.is_contract,
        phone_number: this.employee.phone_number,
        nation: this.employee.nation,
        stay_condition: this.employee.stay_condition,
        email: this.employee.email,

        jumin_tax: this.employee.jumin_tax,
        income_tax: this.employee.income_tax,
        etc_exclude: this.employee.etc_exclude,
        final_payment: this.employee.final_payment,
        severance_pay: this.employee.severance_pay,

        contract_from: this.employee.contract_from,
        contract_to: this.employee.contract_to
      };

      this.in_files.in_date = this.employee.in_date;

      this.leave_files.out_date = this.employee.out_date;
      this.leave_files.jumin_tax = this.employee.jumin_tax;
      this.leave_files.income_tax = this.employee.income_tax;
      this.leave_files.etc_exclude = this.employee.etc_exclude;
      this.leave_files.final_payment = this.employee.final_payment;
      this.leave_files.severance_pay = this.employee.severance_pay;

      this.dependents = [
        ...this.employee.dependents
      ];
    },
    components: {
      EmployeeForm,
      DependentForm
    }
  }
</script>
