import * as http from './http';

export interface Property {
  id: string;
  value: string;
  comment: string;
}

export interface InputProperty {
  year: string;
  month: string;
  employee_id: string;
  properties: Array<Property>;
}

export interface UploadFileData {
  file1: any;
  file2: any;
  file3: any;
}

export function uploadFile(id: string, type: string, files: UploadFileData) {
  let formData = new FormData();

  formData.append('id', id);
  formData.append('type', type);
  if (files.file1 !== null) {
    formData.append('file1', files.file1);
  }
  if (files.file2 !== null) {
    formData.append('file2', files.file2);
  }
  if (files.file3 !== null) {
    formData.append('file3', files.file3);
  }

  return http.post('/api/v1/admin/payslip/file', formData);
}

export function inputProperty(data: InputProperty) {
  const formData = new FormData();

  formData.append('year', data.year);
  formData.append('month', data.month);
  formData.append('employee_id', data.employee_id);
  data.properties.forEach((property, index) => {
    formData.append(`properties[${index}][id]`, property.id);
    formData.append(`properties[${index}][value]`, property.value);
    formData.append(`properties[${index}][comment]`, property.comment);
  });

  return http.post('/api/v1/admin/payslip/input', formData);
}

export function rollbackPayslip(userId: string, year: string, month: string) {
  return http.post('/api/v1/admin/payslip/rollback', {
    user_id: userId,
    year,
    month
  })
}

export function adminConfirm(userId: string, year: string, month: string) {
  return http.post('/api/v1/admin/payslip/confirm', {
    user_id: userId,
    year,
    month
  });
}

export function getUsers(type: string, year: string, month: string, page:number) {
  return http.get(`/api/v1/admin/payslip/user/${type}?year=${year}&month=${month}&page=${page}`);
}

export function getEmployees(userId: number, year: string, month: string, page: number) {
  return http.get(`/api/v1/admin/payslip/user/${userId}/employee?year=${year}&month=${month}&page=${page}`);
}

export function getPayslip(userId: number, year: string, month: string) {
  return http.get(`/api/v1/admin/payslip/${userId}?year=${year}&month=${month}`);
}

export function notify(userId: number, year: string, month: string, type: string) {
  return http.post('/api/v1/admin/payslip/notify', {
    user_id: userId,
    year,
    month,
    type
  });
}
