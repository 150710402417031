import {DispatchContext, Job} from "@/core/types";
import * as excelApi from '@/core/api/excel';
import {UploadExcelData} from "@/core/api/excel";
import {dispatch} from "@/core/dispatcher";
import GetEmployeesJob from "@/core/jobs/payslip/GetEmployeesJob";

export default class UploadExcelJob implements Job {
  private data: UploadExcelData;

  constructor(data: UploadExcelData) {
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    await excelApi.uploadExcel(this.data);

    return dispatch(new GetEmployeesJob(parseInt(this.data.userId), this.data.year, this.data.month, 0));
  }
}
