<template>
  <div>
    <h4 class="page-title">공지사항</h4>

    <notice-list />
  </div>
</template>
<script>
  import NoticeList from "@/components/announcement/notice/NoticeList";
  import {dispatch} from "@/core/dispatcher";
  import LoadAnnouncementJob from "@/core/jobs/announcement/LoadAnnouncementJob";

  export default {
    components: {
      NoticeList
    },
    mounted() {
      dispatch(new LoadAnnouncementJob(0, 'notice'));
    }
  }
</script>
