<template>
  <div v-if="loaded">
    <h4 class="page-title">사용자 정보 수정</h4>
    <user-form />
    <br /><br />
    <property-form />
  </div>
</template>
<script>
  import UserForm from '@/components/user/manage/edit/UserForm';
  import PropertyForm from '@/components/user/manage/edit/property/Index';
  import {dispatch} from "@/core/dispatcher";
  import SelectUserJob from "@/core/jobs/user/SelectUserJob";
  import LoadBasePropertyJob from "@/core/jobs/base-property/LoadBasePropertyJob";

  export default {
    data() {
      return {
        loaded: false
      };
    },
    components: {
      UserForm,
      PropertyForm
    },
    created() {
      dispatch([
        new SelectUserJob(parseInt(this.$route.params.id)),
        new LoadBasePropertyJob({
          page: 0,
          type: 'wage'
        }),
        new LoadBasePropertyJob({
          page: 0,
          type: 'exclude'
        })
      ]).then(() => {
        this.loaded = true;
      });
    }
  }
</script>
