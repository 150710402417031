import {DispatchContext, Job} from "@/core/types";
import * as announcementApi from '@/core/api/announcement';

export default class DestroyAttachmentJob implements Job {
  private id: number;

  constructor(id: number) {
    this.id = id;
  }

  async run(context: DispatchContext): Promise<any> {
    await announcementApi.destroyAttachment(this.id);
  }
}
