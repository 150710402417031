import * as http from './http';

export interface PropertySearch {
  page: number,
  type: string
}

export interface CreateBasePropertyData {
  type: string,
  name: string
}

export interface SyncUserBasePropertyData {
  userId: number,
  propertyIdList: Array<number>
}

export function getProperties(data: PropertySearch) {
  return http.get(`/api/v1/admin/base-property?type=${data.type}&page=${data.page}`);
}

export function createBaseProperty(data: CreateBasePropertyData) {
  return http.post('/api/v1/admin/base-property', data);
}

export function destroyProperty(idList: Array<number>) {
  return http.destroy(`/api/v1/admin/base-property?id[]=${idList.join('&id[]=')}`);
}

export function sync(data: SyncUserBasePropertyData) {
  return http.post('api/v1/admin/user-base-property/sync', {
    user_id: data.userId,
    base_properties: data.propertyIdList
  });
}
