import Page from '@/views/admin/account/Page.vue';
import ManageIndex from '@/views/admin/account/manage/Index.vue';
import ManageCreate from '@/views/admin/account/manage/Create.vue';
import ManageEdit from '@/views/admin/account/manage/Edit.vue';

export default [
  {
    path: 'account',
    component: Page,
    children: [
      {
        path: 'manage',
        component: ManageIndex,
        name: 'account.manage.index'
      },
      {
        path: 'manage/create',
        component: ManageCreate,
        name: 'account.manage.create'
      },
      {
        path: 'manage/:id',
        component: ManageEdit,
        name: 'account.manage.edit'
      }
    ]
  }
];
