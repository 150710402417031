<template>
  <div>
    <table class="table table-bordered">
      <tbody>
      <tr>
        <th>사원번호</th>
        <td>{{ employee.code }}</td>
      </tr>
      <tr>
        <th>이름</th>
        <td>{{ employee.name }}</td>
      </tr>
      <tr>
        <th>생년월일</th>
        <td>{{ employee.birthday }}</td>
      </tr>
      <tr>
        <th>근무타입</th>
        <td>{{ employee.employee_type }}</td>
      </tr>
      <tr>
        <th>연락처</th>
        <td>{{ employee.phone_number }}</td>
      </tr>
      <tr>
        <th>주민(외국인)등록번호</th>
        <td>{{ employee.jumin }}</td>
      </tr>
      <tr>
        <th>입사일</th>
        <td>{{ employee.in_date }}</td>
      </tr>
      <tr>
        <th>이메일</th>
        <td>{{ employee.email }}</td>
      </tr>

      <template v-if="employee.employee_type === '일반근로자'">
        <tr>
          <th>급여타입</th>
          <td>{{ employee.pay_type }}</td>
        </tr>
        <tr>
          <th>주소정근로시간</th>
          <td>{{ employee.working_hour }}</td>
        </tr>
        <tr>
          <th>직급</th>
          <td>{{ employee.position }}</td>
        </tr>
        <tr>
          <th>부서</th>
          <td>{{ employee.department }}</td>
        </tr>
        <tr>
          <th>총월소득액(비과세제외)</th>
          <td>{{ employee.income }}</td>
        </tr>
        <tr>
          <th>계약직</th>
          <td>{{ employee.is_contract ? 'Y' : 'N' }}</td>
        </tr>
        <tr v-if="employee.is_contract">
          <th>계약일자</th>
          <td>{{ employee.contract_from }} ~ {{ employee.contract_to }}</td>
        </tr>
        <tr>
          <th>업무내용</th>
          <td>
            <textarea class="form-control" rows="5" readonly>{{ employee.role }}</textarea>
          </td>
        </tr>
      </template>
      <template v-else-if="employee.employee_type === '사업소득자'">
        <tr>
          <th>직급</th>
          <td>{{ employee.position }}</td>
        </tr>
        <tr>
          <th>부서</th>
          <td>{{ employee.department }}</td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <th>국적</th>
          <td>{{ employee.nation }}</td>
        </tr>
        <tr>
          <th>체류자격</th>
          <td>{{ employee.stay_condition }}</td>
        </tr>
      </template>
      <tr>
        <th>취득 신고서</th>
        <td>
          <v-input-file
            :id="`regist_file_${employee.user_id}_${employee.id}`"
            :value="value.regist"
            @input="update($event, 'regist')"
          />
        </td>
      </tr>
      </tbody>
    </table>
    <h3>피부양자</h3>
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>성명</th>
        <th>관계</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="dependent in employee.dependents">
        <tr>
          <td>{{ dependent.name }}</td>
          <td>{{ dependent.relationship }}</td>
        </tr>
      </template>
      </tbody>
    </table>
    <template v-if="employee.dependent_file !== null">
      <h5>가족관계증명서</h5>
      <a :href="employee.dependent_file" target="_blank" class="btn btn-default">다운로드</a>
    </template>
  </div>
</template>
<script>
  import {dispatch} from "@/core/dispatcher";
  import UpdateEmployeeJob from "@/core/jobs/employee/UpdateEmployeeJob";
  import LoadNotCompletedUsersJob from "@/core/jobs/employee/LoadNotCompletedUsersJob";

  export default {
    props: ['employee', 'value'],
    data() {
      return {
        regist_file: null
      };
    },
    methods: {
      update(v, type) {
        if (type === 'regist') {
          this.regist_file = v;
        }

        this.$emit('input', {
          regist: this.regist_file
        });
      }
    }
  }
</script>
