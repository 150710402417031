<template>
  <div>
    <search-form
      @search="search"
    />
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-list"></i> 사용자 목록
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th width="70"></th>
            <th width="70"></th>
            <th>아이디</th>
            <th>회사명</th>
            <th>휴대전화번호</th>
            <th>이메일</th>
            <th>급여지급일</th>
            <th>사업자등록번호</th>
            <th>가입일</th>
            <th>담당자</th>
            <th width="120"></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(user, index) in users">
            <tr :key="`user-list-${user.id}`">
              <td align="center">
                <input type="checkbox" :value="user.id" v-model="checkedUser">
              </td>
              <td align="center">{{ total - (per_page * (page - 1)) - index }}</td>
              <td>{{ user.userid }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.phone_number }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.pay_day_type === 'this_month' ? '당월' : '익월' }} {{ user.pay_day }}일</td>
              <td>{{ user.company_number }}</td>
              <td>{{ user.created_at }}</td>
              <td>
                {{ user.admin_name }}
              </td>
              <td>
                <button type="button" class="btn btn-default btn-sm" @click="openModal(user)">
                  확인
                </button>
                &nbsp;
                <button type="button" class="btn btn-danger btn-sm" @click="destroyUser(user)">
                  삭제
                </button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>

        <pagination
          :page="page"
          :per-page="per_page"
          :total="total"
          @move-page="movePage"
        />
      </div>
      <div class="card-body">
        <div class="form-inline">
          선택한 사용자의 담당자를
          <select class="form-control" v-model="adminId">
            <template v-for="account in adminAccounts">
              <option :value="account.id">{{ account.userid }}</option>
            </template>
          </select>
          로
          <button type="button" class="btn btn-primary" @click="changeUserAdmin">
            변경
          </button>
        </div>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" ref="modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel" v-if="selectedUser !== null">{{ selectedUser.name }}</h4>
          </div>
          <div class="modal-body">
            <user-info
              v-if="selectedUser !== null"
              :user="selectedUser"
              v-model="userInput"
            />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save">확인</button>
            <button type="button" class="btn btn-default" data-dismiss="modal">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import {dispatch} from "@/core/dispatcher";
  import LoadUncertificatedUserListJob from "@/core/jobs/user/LoadUncertificatedUserListJob";

  import UserInfo from './UserInfo';
  import SearchForm from './SearchForm';
  import UpdateUserJob from "@/core/jobs/user/UpdateUserJob";
  import ConfirmUserJob from "@/core/jobs/user/ConfirmUserJob";
  import DestroyUserJob from "@/core/jobs/user/DestroyUserJob";
  import * as userApi from "@/core/api/user";
  import * as accountApi from '@/core/api/account';

  export default {
    data() {
      return {
        adminAccounts: [],
        adminId: '',
        checkedUser: [],
        selectedUser: null,
        certified: false,
        userid: '',
        userInput: {
          certified: '',
          admin_id: ''
        }
      };
    },
    computed: {
      ...mapState('user', {
        users: state => state.users,
        page: state => state.page,
        total: state => state.total,
        per_page: state => state.per_page
      }),
      ...mapState('account', {
        accounts: state => state.accounts
      })
    },
    methods: {
      destroyUser(user) {
        if (!confirm('정말 삭제하시겠습니까?')) {
          return false;
        }

        userApi.destroy(user.id).then(() => {
          dispatch(new LoadUncertificatedUserListJob({
            page: this.page,
            certified: this.certified,
            userid: this.userid
          }));
        });
      },
      openModal(user) {
        this.selectedUser = user;

        this.userInput.admin_id = user.admin_id;
        this.userInput.certified = user.certified ? 'confirm' : 'reject';

        $(this.$refs.modal).modal('show');
      },
      search({ certified, userid }) {
        this.certified = certified;
        this.userid = userid;

        dispatch(new LoadUncertificatedUserListJob({
          page: 0,
          certified,
          userid
        }));
      },
      movePage(page) {
        dispatch(new LoadUncertificatedUserListJob({
          page,
          certified: this.certified,
          userid: this.userid
        }));
      },
      save() {
        if (this.userInput.admin_id === '') {
          alert('담당 관리자를 선택하세요.');
          return;
        }

        if (this.userInput.certified === 'confirm' && ! confirm('정말 계정을 인증하시겠습니까?')) {
          return;
        }

        if (this.userInput.certified !== 'confirm' && ! confirm('정말 계정 인증을 해제하시겠습니까?')) {
          return;
        }

        $(this.$refs.modal).modal('hide');

        dispatch(new ConfirmUserJob(this.selectedUser.id, {
          admin_id: this.userInput.admin_id,
          certified: this.userInput.certified === 'confirm'
        })).then(() => {
          alert('처리 완료');

          dispatch(new LoadUncertificatedUserListJob({
            page: this.page,
            certified: this.certified,
            userid: this.userid
          }));
        })
      },
      toggleCertified({ userId, accountId, certified }) {
        if (accountId === '') {
          alert('담당 관리자를 선택하세요.');
          return;
        }

        if (certified && ! confirm('정말 계정을 인증하시겠습니까?')) {
          return;
        }

        if (!certified && ! confirm('정말 계정 인증을 해제하시겠습니까?')) {
          return;
        }

        dispatch(new ConfirmUserJob(userId, {
          admin_id: accountId,
          certified
        }));
      },
      async changeUserAdmin() {
        if (this.adminId === '') {
          alert('관리자를 선택해주세요');
          return;
        }

        if (! confirm('정말 담당자를 수정하시겠습니까?')) {
          return;
        }

        await userApi.updateUserAdmin(this.checkedUser, this.adminId);

        alert('수정 완료');

        await dispatch(new LoadUncertificatedUserListJob({
          page: this.page,
          certified: this.certified,
          userid: this.userid
        }));
      }
    },
    mounted() {
      accountApi.getAccounts().then(response => {
        this.adminAccounts = response.data.data;
      });
    },
    components: {
      UserInfo,
      SearchForm
    }
  }
</script>
