import {DispatchContext, Job} from "@/core/types";

export default class SelectImageJob implements Job {
  private readonly url: string;

  constructor(url: string) {
    this.url = url;
  }

  async run(context: DispatchContext): Promise<any> {
    context.store.commit('imageModal/imageSelected', this.url);
  }
}
