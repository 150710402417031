<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user-shield"></i> 계정 정보
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="userid">아이디</label>
              <input type="text" class="form-control" id="userid" readonly :value="admin.userid">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="email">이메일</label>
              <v-input
                id="email"
                type="text"
                :errors="errors.email"
                v-model="userInput.email"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="phone_number">휴대전화번호</label>
              <v-input
                id="phone_number"
                type="text"
                :errors="errors.phone_number"
                v-model="userInput.phone_number"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="password">비밀번호</label>
              <v-input
                id="password"
                type="password"
                :errors="errors.password"
                v-model="userInput.password"
              />
            </div>
          </div><div class="col-md-4">
          <div class="form-group">
            <label for="password_confirmation">비밀번호</label>
            <v-input
              id="password_confirmation"
              type="password"
              :errors="errors.password_confirmation"
              v-model="userInput.password_confirmation"
            />
          </div>
        </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="update">
        수정
      </button>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';
  import {dispatch} from "@/core/dispatcher";
  import UpdateAccountJob from "@/core/jobs/auth/UpdateAccountJob";

  export default {
    data() {
      return {
        userInput: {
          email: '',
          phone_number: '',
          password: '',
          password_confirmation: ''
        },
        errors: {}
      };
    },
    computed: {
      ...mapState('auth', {
        admin: state => state.admin
      })
    },
    methods: {
      update() {
        dispatch(new UpdateAccountJob({
          email: this.userInput.email,
          phone_number: this.userInput.phone_number,
          password: this.userInput.password,
          password_confirmation: this.userInput.password_confirmation
        })).then(() => {
          alert('수정 완료');
          this.userInput.password = '';
          this.userInput.password_confirmation = '';
          this.errors = {};
        }).catch(err => {
          if (err.response.status === 422) {
            this.errors = err.response.data;
          }
        });
      }
    },
    mounted() {
      this.userInput = {
        email: this.admin.email,
        phone_number: this.admin.phone_number,
        password: '',
        password_confirmation: ''
      };
    }
  }
</script>
