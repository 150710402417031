import * as http from './http';

export interface CreateAnnouncementData {
  title: string;
  content: string;
  type: string;
  files: Array<any>;
}

export interface UpdateAnnouncementData {
  title: string;
  content: string;
  files: Array<any>;
}

export function getAnnouncements(page: number, type: string) {
  return http.get(`/api/v1/admin/announcement/${type}?page=${page}`);
}

export function createAnnouncement(data: CreateAnnouncementData) {
  return http.post('/api/v1/admin/announcement', data);
}

export function updateAnnouncement(id: number, data: UpdateAnnouncementData) {
  return http.put(`/api/v1/admin/announcement/${id}`, data);
}

export function destroyAnnouncement(id: number) {
  return http.destroy(`/api/v1/admin/announcement/${id}`);
}

export function destroyAttachment(id: number) {
  return http.destroy(`/api/v1/admin/announcement/attachment/${id}`);
}
