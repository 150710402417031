import {DispatchContext, Job} from "@/core/types";
import {getImages} from "@/core/api/image";

export default class LoadImagesJob implements Job {
  private readonly page: number;

  constructor(page: number) {
    this.page = page;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getImages(this.page);

    context.store.commit('imageModal/setImages', response.data.data);
  }
}
