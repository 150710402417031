<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user-tie"></i> 사원 정보
      </div>
      <div class="card-body">
        <employee-form
          v-model="userInput"
          :errors="errors"
        />
      </div>
    </div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-hands-helping"></i> 피부양자
      </div>
      <div class="card-body">
        <dependent-form
          v-model="dependents"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-heading">
            <i class="fa fa-plus"></i> 입사정보
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="in_date">입사일</label>
                  <v-date-picker
                    id="in_date"
                    :value="in_files.in_date"
                    :errors="errors.in_date"
                    @input="updateInFiles($event, 'in_date')"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="in_file">취득 신고서</label>
                  <v-input-file
                    id="in_file"
                    :value="in_files.file"
                    @input="updateInFiles($event, 'file')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-heading">
            <i class="fa fa-minus"> 퇴사 정보</i>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="out_date">퇴사일</label>
                  <v-date-picker
                    id="out_date"
                    :value="leave_files.out_date"
                    :errors="errors.out_date"
                    @input="updateLeaveFiles($event, 'out_date')"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="out">퇴직소득원천징수영수증</label>
                  <v-input-file
                    id="out"
                    :value="leave_files.out"
                    @input="updateLeaveFiles($event, 'out')"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="out2">상실신고서</label>
                  <v-input-file
                    id="out2"
                    :value="leave_files.out2"
                    @input="updateLeaveFiles($event, 'out2')"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="severance_pay">퇴직금</label>
                  <v-input
                    type="number"
                    id="severance_pay"
                    :value="leave_files.severance_pay"
                    @input="updateLeaveFiles($event, 'severance_pay')"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="income_tax">소득세</label>
                  <v-input
                    type="number"
                    id="income_tax"
                    :value="leave_files.income_tax"
                    @input="updateLeaveFiles($event, 'income_tax')"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="jumin_tax">주민세</label>
                  <v-input
                    type="number"
                    id="jumin_tax"
                    :value="leave_files.jumin_tax"
                    @input="updateLeaveFiles($event, 'jumin_tax')"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="etc_exclude">기타공제</label>
                  <v-input
                    type="number"
                    id="etc_exclude"
                    :value="leave_files.etc_exclude"
                    @input="updateLeaveFiles($event, 'etc_exclude')"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="final_payment">차인지급액</label>
                  <v-input
                    type="number"
                    id="final_payment"
                    :value="leave_files.final_payment"
                    @input="updateLeaveFiles($event, 'final_payment')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <button type="button" class="btn btn-primary btn-sm" @click="save">
        확인
      </button>
      &nbsp;
      <button type="button" class="btn btn-default btn-sm" @click="$router.back()">
        뒤로
      </button>
    </div>
  </div>
</template>
<script>
  import EmployeeForm from "@/components/user/manage/employee/EmployeeForm";
  import DependentForm from "@/components/user/manage/employee/DependentForm";
  import {dispatch} from "@/core/dispatcher";
  import CreateEmployeeJob from "@/core/jobs/user/CreateEmployeeJob";

  export default {
    data() {
      return {
        dependents: [],
        userId: 0,
        userInput: {
          code: '',
          name: '',
          position: '',
          department: '',
          employee_type: '',
          pay_type: '',
          jumin: '',
          working_hour: 0,
          income: 0,
          role: null,
          is_contract: false,
          phone_number: '',
          nation: '',
          stay_condition: '',
          email: ''
        },
        in_files: {
          in_date: '',
          file: null
        },
        leave_files: {
          out_date: '',
          out: null,
          out2: null,
          income_tax: 0,
          jumin_tax: 0,
          etc_exclude: 0,
          final_payment: 0,
          severance_pay: 0
        },
        errors: {}
      }
    },

    watch: {
      'leave_files.severance_pay': function(v) {
        this.leave_files.final_payment =
          v -
          this.leave_files.income_tax -
          this.leave_files.jumin_tax -
          this.leave_files.etc_exclude;
      },
      'leave_files.income_tax': function(v) {
        this.leave_files.final_payment =
          this.leave_files.severance_pay -
          v -
          this.leave_files.jumin_tax -
          this.leave_files.etc_exclude;
      },
      'leave_files.jumin_tax': function(v) {
        this.leave_files.final_payment =
          this.leave_files.severance_pay -
          this.leave_files.income_tax -
          v -
          this.leave_files.etc_exclude;
      },
      'leave_files.etc_exclude': function(v) {
        this.leave_files.final_payment =
          this.leave_files.severance_pay -
          this.leave_files.income_tax -
          this.leave_files.jumin_tax -
          v;
      }
    },
    methods: {
      save() {
        let data = {
          user_id: this.userId,
          ...this.userInput,
          dependents: [
            ...this.dependents
          ],
          jumin_tax: this.leave_files.jumin_tax,
          income_tax: this.leave_files.income_tax,
          etc_exclude: this.leave_files.etc_exclude,
          final_payment: this.leave_files.final_payment,
          severance_pay: this.leave_files.severance_pay
        };

        if (this.in_files.in_date !== '') {
          data.in_date = this.in_files.in_date;
        }

        if (this.leave_files.out_date !== '') {
          data.out_date = this.leave_files.out_date;
        }

        if (this.in_files.file !== null) {
          data['regist_file'] = this.in_files.file;
        }

        if (this.leave_files.out !== null) {
          data['out_file'] = this.leave_files.out;
        }

        if (this.leave_files.out2 !== null) {
          data['out_file2'] = this.leave_files.out2;
        }


        dispatch(new CreateEmployeeJob(data)).then(() => {
          alert('추가 완료');
          this.$router.back();
        }).catch(err => {
          if (err.response.status === 422) {
            this.errors = err.response.data;
          }
        });
      },
      updateInFiles(v, type) {
        this.in_files[type] = v;
      },
      updateLeaveFiles(v, type) {
        this.leave_files[type] = v;
      },
    },
    components: {
      EmployeeForm,
      DependentForm
    },
    mounted() {
      this.userId = parseInt(this.$route.params.id);
    }
  }
</script>
