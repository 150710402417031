<template>
  <div>
    <h4 class="page-title">관리자 계정정보 수정</h4>
    <edit-form
    />
  </div>
</template>
<script>
  import EditForm from "@/components/account/manage/EditForm";

  export default {
    components: {
      EditForm
    }
  }
</script>
