import {DispatchContext, Job} from "@/core/types";

import * as accountApi from '@/core/api/account';

export default class LoadAccountsJob implements Job {
  async run(context: DispatchContext): Promise<any> {
    const response = await accountApi.getAccounts();

    context.store.commit('account/setAccounts', response.data.data);
  }
}
