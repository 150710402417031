import {DispatchContext, Job} from "@/core/types";
import * as payslipApi from '@/core/api/payslip';

export default class RollbackPayslipJob implements Job {
  private userId: string;
  private year: string;
  private month: string;

  constructor(userId: string, year: string, month: string) {
    this.userId = userId;
    this.year = year;
    this.month = month;
  }

  async run(context: DispatchContext): Promise<any> {
    await payslipApi.rollbackPayslip(this.userId, this.year, this.month);
  }
}
