<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user"></i> 사용자 정보
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="name">회사명</label>
          <input type="text" :value="user.name" readonly class="form-control">
        </div>
        <div class="form-group">
          <label>귀속월</label>
          <input type="text" :value="payslip.payslip_date" readonly class="form-control">
        </div>
        <div class="form-group">
          <label>지급일</label>
          <input type="text" :value="payslip.pay_date" readonly class="form-control">
        </div>
        <div class="text-right">
          <div class="form-group">
            <button type="button" class="btn btn-default btn-sm" @click="rollback">
              사용자 입력 가능 상태로 되돌리기
            </button>
            &nbsp;
            <button type="button" class="btn btn-primary btn-sm" @click="confirm">
              전체 사원 금액 확정하기
            </button>
          </div>
          <div class="form-group">
            <button type="button" class="btn btn-primary btn-sm" @click="notifyPayslipCompleted">
              급여입력 완료 알림
            </button>
            &nbsp;
            <button type="button" class="btn btn-primary btn-sm" @click="notifyTaxCompleted">
              원천세 완료 알림
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('payslip', {
        user: state => state.selectedUser,
        payslip: state => state.payslip
      })
    },
    methods: {
      notifyPayslipCompleted() {
        this.$emit('notify-payslip-completed');
      },
      notifyTaxCompleted() {
        this.$emit('notify-tax-completed');
      },
      rollback() {
        this.$emit('rollback');
      },
      confirm() {
        this.$emit('confirm');
      }
    }
  }
</script>
