import Admin from '@/Admin.vue';
import AdminIndex from '@/views/admin/Index.vue';

import store from '@/store'
import user from './user';
import baseProperty from './base-property';
import account from './account';
import payslip from './payslip';
import myPage from './my-page';
import announcement from "./announcement";
import alarm from "./alarm";

// @ts-ignore
import jscookie from 'js-cookie';
import {dispatch} from "@/core/dispatcher";
import GetAdminFromSessionTokenJob from "@/core/jobs/auth/GetAdminFromSessionTokenJob";

export default [
  {
    path: '/',
    component: Admin,
    children: [
      {
        path: '',
        component: AdminIndex,
        name: 'dashboard'
      },
      ...user,
      ...baseProperty,
      ...account,
      ...payslip,
      ...myPage,
      ...announcement,
      ...alarm
    ],
    beforeEnter(to: any, from: any, next: any) {
      // @ts-ignore
      if (store.state.auth.admin === null) {
        const token = jscookie.get('hoyeon-admin-session');
        if (token !== undefined) {
          dispatch(new GetAdminFromSessionTokenJob()).then(() => {
            next(to);
          }).catch(() => {
            jscookie.remove('hoyeon-admin-session');
            store.commit('auth/setAdmin', null);
            next({
              name: 'login'
            });
          });
        } else {
          next({
            name: 'login'
          });
        }
      } else {
        next();
      }
    }
  }
];
