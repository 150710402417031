import {DispatchContext, Job} from "@/core/types";
import {getAnnouncements} from "@/core/api/announcement";

export default class LoadAnnouncementJob implements Job {
  private readonly type: string;
  private readonly page: number;

  constructor(page: number, type: string) {
    this.page = page;
    this.type = type;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await getAnnouncements(this.page, this.type);

    context.store.commit('announcement/setAnnouncements', response.data.data);
  }
}
