import {PaginateData} from "@/core/types";

export default {
  namespaced: true,
  state: {
    users: [],
    page: 0,
    per_page: 0,
    total: 0,
    employee: {
      employees: [],
      page: 0,
      per_page: 0,
      total: 0
    },
  },
  getters: {
    inEmployees(state: any) {
      return state.employee.employees.filter((employee: any) => employee.in_completed === false);
    },
    outEmployees(state: any) {
      return state.employee.employees.filter((employee: any) => employee.out_completed !== false);
    }
  },
  mutations: {
    setUsers(state: any, data: PaginateData) {
      state.users = [
        ...data.data
      ];
      state.page = data.page;
      state.per_page = data.per_page;
      state.total = data.total;
    },
    setEmployees(state: any, data: PaginateData) {
      state.employee.employees = [
        ...data.data
      ];
      state.employee.page = data.page;
      state.employee.per_page = data.per_page;
      state.employee.total = data.total;
    }
  }
}
