<template>
  <div>
    <h4 class="page-title">관리자 계정 생성</h4>
    <create-form />
  </div>
</template>
<script>
  import CreateForm from "@/components/account/manage/CreateForm";

  export default {
    components: {
      CreateForm
    }
  }
</script>
