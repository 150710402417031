// @ts-ignore
import jscookie from 'js-cookie';
import moment, {Moment} from 'moment';
import axios from 'axios';

interface Token {
  token: string;
  expiredAt: Moment;
}

let token: Token | null = null;

const http = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL
});

function clearToken() {
  jscookie.remove('hoyeon-admin-session');

  token = null;
}

function saveToken(t: Token): void {
  if (t.token !== undefined && t.expiredAt !== undefined) {
    jscookie.set('hoyeon-admin-session', {
      token: t.token,
      expiredAt: t.expiredAt.format('YYYY-MM-DD HH:mm:ss')
    });

    token = t;
  }
}

function parseToken(): null | Token {
  let t = token;
  const sess = jscookie.get('hoyeon-admin-session');

  if (t === null && sess !== undefined && sess !== '') {
    const json = JSON.parse(sess);

    t = {
      token: json.token,
      expiredAt: moment(json.expiredAt)
    };
  }

  return t;
}

async function refresh(t: string): Promise<Token | null> {
  try {
    const response = await http.post('/api/v1/admin/auth/refresh', {}, {
      headers: {
        'Authorization': `Bearer ${t}`
      }
    });

    let token = {
      token: response.data.access_token,
      expiredAt: moment().add(response.data.expires_in, 's')
    };

    saveToken(token);

    return token;
  } catch (e) {
    clearToken();

    await logout();

    return null;
  }
}

export async function login(userid: string, password: string): Promise<any> {
  const response = await http.post('/api/v1/admin/auth/login', {
    userid,
    password
  });

  saveToken({
    token: response.data.access_token,
    expiredAt: moment().add(response.data.expires_in, 's')
  });
}

export async function logout() {
  const token = await getToken();

  if (token !== null) {
    try {
      await http.get('/api/v1/admin/auth/logout', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch(e) {
    }
  }

  clearToken();
}

export async function getToken(): Promise<null | string> {
  let token = parseToken();

  if (token === null) return null;

  if (token.expiredAt.diff(moment(), 's') <= 900) {
    token = await refresh(token.token);
  }

  if (token === null) {
    return null;
  } else {
    return token.token;
  }
}
