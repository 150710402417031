import {DispatchContext, Job} from "@/core/types";
import * as userApi from '@/core/api/user';
import {dispatch} from "@/core/dispatcher";
import LoadUserListJob from "@/core/jobs/user/LoadUserListJob";

export default class DestroyUserJob implements Job {
  private id: number;

  constructor(id: number) {
    this.id = id;
  }

  async run(context: DispatchContext): Promise<any> {
    await userApi.destroy(this.id);

    return dispatch(new LoadUserListJob({
      page: context.store.state.user.page,
      certified: true
    }));
  }
}
