import {DispatchContext, Job} from "@/core/types";
import {updateAnnouncement} from "@/core/api/announcement";

export default class UpdateAnnouncementJob implements Job {
  private readonly id: number;
  private readonly title: string;
  private readonly content: string;
  private readonly files: Array<any>;

  constructor(id: number, title: string, content: string, files: Array<any>) {
    this.id = id;
    this.title = title;
    this.content = content;
    this.files = files;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await updateAnnouncement(this.id, {
      title: this.title,
      content: this.content,
      files: this.files
    });
  }
}
