<template>
  <div>
    <h4 class="page-title">급여명세서</h4>

    <search
      v-model="date"
    />
    <br />
    <div class="row">
      <div class="col-md-3">
        <user-list
          title="입력대기"
          type="pending"
          :date="date"
        />
      </div>
      <div class="col-md-3">
        <user-list
          title="입력완료"
          type="user_wrote"
          :date="date"
        />
      </div>
      <div class="col-md-3">
        <user-list
          title="관리자확정"
          type="admin_confirm"
          :date="date"
        />
      </div>
      <div class="col-md-3">
        <user-list
          title="처리완료"
          type="finished"
          :date="date"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <user-list
          title="취소요청"
          type="user_cancel"
          :date="date"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import UserList from "@/components/payslip/index/UserList";
  import Search from "@/components/payslip/index/Search";
  import moment from "moment";

  export default {
    data() {
      return {
        date: {
          year: '',
          month: ''
        }
      }
    },
    components: {
      Search,
      UserList
    },
    created() {
      if (
        this.$route.query.year !== undefined ||
        this.$route.query.month !== undefined
      ) {
        this.date.year = this.$route.query.year;
        this.date.month = this.$route.query.month;
      } else {
        this.date.year = moment().format('YYYY');
        this.date.month = moment().format('MM');
      }
    }
  }
</script>
