import * as http from './http';

export interface UpdateEmployeeData {
  regist_file?: any;
  out_file?: any;
  out_file2?: any;
  income_tax: string;
  jumin_tax: string;
  etc_exclude: string;
  final_payment: string;
}

export interface UpdateEmployeeInfoData {
  code: string,
  name: string,
  birthday: string,
  position: string,
  department: string,
  in_date: string,
  out_date: string,
  employee_type: string,
  pay_type: string,
  jumin: string
}

export interface CreateEmployeeData {
  user_id: number,
  code: string,
  name: string,
  birthday: string,
  position: string,
  department: string,
  in_date: string,
  out_date: string,
  employee_type: string,
  pay_type: string,
  jumin: string,
  dependents: Array<any>,
  role: string
}

export function getEmployees(userId: number, page: number) {
  return http.get(`/api/v1/admin/employee/${userId}?page=${page}`);
}

export function getNotCompletedEmployees(userId: number, page: number) {
  return http.get(`/api/v1/admin/employee/not-completed-employee?user_id=${userId}&page=${page}`);
}

export function getNotCompletedUsers(adminId: number, page: number) {
  return http.get(`/api/v1/admin/employee/not-completed?admin_id=${adminId}&page=${page}`);
}

export function createEmployee(data: CreateEmployeeData) {
  return http.post('/api/v1/admin/employee', data);
}

export function updateEmployeeInfo(id: number, data: UpdateEmployeeInfoData) {
  return http.put(`/api/v1/admin/employee/${id}`, data);
}

export function deleteEmployees(id: Array<number>) {
  return http.destroy('/api/v1/admin/employee', {
    id
  });
}

export function updateEmployee(id: number, data: UpdateEmployeeData) {
  let formData = new FormData();

  if (data.regist_file !== undefined && data.regist_file !== null) {
    formData.append('regist_file', data.regist_file, data.regist_file.name);
  }

  if (data.out_file !== undefined && data.out_file !== null) {
    formData.append('out_file', data.out_file, data.out_file.name);
  }

  if (data.out_file2 !== undefined && data.out_file2 !== null) {
    formData.append('out_file2', data.out_file2, data.out_file2.name);
  }

  if (data.income_tax !== undefined) {
    formData.append('income_tax', data.income_tax);
  }

  if (data.jumin_tax !== undefined) {
    formData.append('jumin_tax', data.jumin_tax);
  }

  if (data.etc_exclude !== undefined) {
    formData.append('etc_exclude', data.etc_exclude);
  }

  if (data.final_payment !== undefined) {
    formData.append('final_payment', data.final_payment);
  }

  return http.put(`/api/v1/admin/employee/${id}`, formData);
}
