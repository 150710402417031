<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <i class="fa fa-user"></i> 사원 기본정보
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="code">사원번호</label>
              <v-input
                type="text"
                id="code"
                :value="value.code"
                :errors="errors.code"
                @input="update($event, 'code')"
                ref="code"
                :readonly="isUpdate"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">이름</label>
              <v-input
                type="text"
                id="name"
                :value="value.name"
                :errors="errors.name"
                @input="update($event, 'name')"
                ref="name"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="employee_type">근무타입</label>
              <v-select
                id="employee_type"
                :value="value.employee_type"
                :errors="errors.employee_type"
                @input="update($event, 'employee_type')"
                ref="employee_type"
              >
                <option value="">선택하세요</option>
                <option value="일반근로자">일반근로자</option>
                <option value="사업소득자">사업소득자</option>
                <option value="일용근로자">일용근로자</option>
              </v-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="phone_number">연락처</label>
              <v-input
                id="phone_number"
                :value="value.phone_number"
                :errors="errors.phone_number"
                @input="update($event, 'phone_number')"
                ref="phone_number"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="email">이메일</label>
              <v-input
                type="text"
                id="email"
                :value="value.email"
                :errors="errors.email"
                @input="update($event, 'email')"
                ref="email"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group" v-if="! isUpdate">
              <label for="jumin">주민(외국인)등록번호</label>
              <v-input
                type="text"
                id="jumin"
                :value="value.jumin"
                :errors="errors.jumin"
                @input="update($event, 'jumin')"
                ref="jumin"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="value.employee_type === '일반근로자'">
      <div class="card-heading">
        <i class="fa fa-user"> 사원 추가정보</i>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="pay_type">급여타입</label>
              <v-select
                id="pay_type"
                :value="value.pay_type"
                :errors="errors.pay_type"
                @input="update($event, 'pay_type')"
                ref="pay_type"
              >
                <option value="">선택하세요</option>
                <option value="월급제">월급제</option>
                <option value="시급제">시급제</option>
              </v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="working_hour">주소정근로시간</label>
              <v-input
                type="text"
                id="working_hour"
                :value="value.working_hour"
                :errors="errors.working_hour"
                @input="update($event, 'working_hour')"
                ref="working_hour"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="position">직급</label>
              <v-input
                type="text"
                id="position"
                :value="value.position"
                :errors="errors.position"
                @input="update($event, 'position')"
                ref="position"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="department">부서</label>
              <v-input
                type="text"
                id="department"
                :value="value.department"
                :errors="errors.department"
                @input="update($event, 'department')"
                ref="department"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="income">총월소득액(비과세제외)</label>
              <v-input
                type="text"
                id="income"
                :value="value.income"
                :errors="errors.income"
                @input="update($event, 'income')"
                ref="income"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="is_contract">계약직</label><br />
              <input
                type="checkbox"
                id="is_contract"
                :checked="value.is_contract"
                @input="update($event.target.checked, 'is_contract')"
                ref="is_contract">
            </div>
          </div>
          <div class="col-md-3" v-if="value.is_contract">
            <div class="form-group">
              <label for="contract_from">계약시작일자</label>
              <v-date-picker
                id="contract_from"
                :value="value.contract_from"
                :errors="errors.contract_from"
                ref="contract_from"
                @input="update($event, 'contract_from')"
              />
            </div>
          </div>
          <div class="col-md-3" v-if="value.is_contract">
            <div class="form-group">
              <label for="contract_to">계약종료일자</label>
              <v-date-picker
                id="contract_to"
                :value="value.contract_to"
                :errors="errors.contract_to"
                ref="contract_to"
                @input="update($event, 'contract_to')"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="role">업무내용</label>
              <textarea
                class="form-control"
                :class="{'form-error': errors.role !== undefined}"
                id="role"
                @input="update($event.target.value, 'role')"
                ref="role"
                rows="3">{{ value.role }}</textarea>
              <div class="error-message" v-if="errors.role !== undefined">
                <template v-for="error in errors.role">
                  {{ error }}
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-else-if="value.employee_type === '사업소득자'">
      <div class="card-heading">
        <i class="fa fa-user"> 사원 추가정보</i>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="position">직급</label>
              <v-input
                type="text"
                id="position"
                :value="value.position"
                :errors="errors.position"
                @input="update($event, 'position')"
                ref="position"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="department">부서</label>
              <v-input
                type="text"
                id="department"
                :value="value.department"
                :errors="errors.department"
                @input="update($event, 'department')"
                ref="department"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-else-if="value.employee_type === '일용근로자'">
      <div class="card-heading">
        <i class="fa fa-user"> 사원 추가정보</i>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="nation">국적</label>
              <v-input
                type="text"
                id="nation"
                :value="value.nation"
                :errors="errors.nation"
                @input="update($event, 'nation')"
                ref="nation"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="stay_condition">체류자격</label>
              <v-input
                type="text"
                id="stay_condition"
                :value="value.stay_condition"
                :errors="errors.stay_condition"
                @input="update($event, 'stay_condition')"
                ref="stay_condition"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Datepicker from 'vuejs-datepicker';
  import {ko} from 'vuejs-datepicker/dist/locale';
  import TemplateList from "@/components/announcement/notice/TemplateList";

  export default {
    props: ['value', 'errors', 'isUpdate'],
    data() {
      return {
        ko
      };
    },
    methods: {
      update(v, field) {
        let value = v;

        if (field === 'role' && value === '') {
          value = null;
        }

        this.$emit('input', {
          ...this.value,
          [field]: value
        });
      }
    },
    components: {
      TemplateList,
      Datepicker
    }
  }
</script>
