import {DispatchContext, Job} from "@/core/types";
import {Admin} from "@/core/types/model";

export default class SelectAccountJob implements Job {
  private readonly _id: number;

  constructor(id: number) {
    this._id = id;
  }

  async run(context: DispatchContext): Promise<any> {
    const admins = context.store.state.account.accounts.filter((account: Admin) => account.id === this._id);

    return admins[0];
  }
}
