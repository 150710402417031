import store from '@/store';

export function getImageButton(callback: any) {
  return function(context: any) {
    // @ts-ignore
    const ui = $.summernote.ui;

    const button = ui.button({
      contents: '<i class="fa fa-photo-video"></i>',
      tooltip: '이미지',
      click: function() {
        store.commit('imageModal/openModal', callback);
      }
    });

    return button.render();
  };
}
