import {DispatchContext, Job} from "@/core/types";

import * as userApi from '@/core/api/user';
import * as accountApi from '@/core/api/account';
import {SearchData} from "@/core/api/user";

export default class LoadUncertificatedUserListJob implements Job {
  private readonly searchData: SearchData;

  constructor(searchData: SearchData) {
    this.searchData = searchData;
  }

  async run(context: DispatchContext): Promise<any> {
    const response = await userApi.paginate(this.searchData);
    const accountResponse = await accountApi.getAccounts();

    context.store.commit('user/setUser', response.data.data);
    context.store.commit('account/setAccounts', accountResponse.data.data);
  }
}
