<template>
  <div>
    <table class="table">
      <colgroup>
        <col width="120" />
        <col width="*" />
      </colgroup>
      <tbody>
      <tr>
        <th>아이디</th>
        <td>{{ user.userid }}</td>
      </tr>
      <tr>
        <th>회사명</th>
        <td>{{ user.name }}</td>
      </tr>
      <tr>
        <th>휴대전화번호</th>
        <td>{{ user.phone_number }}</td>
      </tr>
      <tr>
        <th>이메일</th>
        <td>{{ user.email }}</td>
      </tr>
      <tr>
        <th>급여지급일</th>
        <td>
          {{ user.pay_day_type === 'this_month' ? '당월' : '익월' }}
          {{ user.pay_day }}일
        </td>
      </tr>
      <tr>
        <th>사업자등록번호</th>
        <td>
          {{ user.company_number }}
        </td>
      </tr>
      <tr>
        <th>가입일</th>
        <td>
          {{ user.created_at }}
        </td>
      </tr>
      <tr>
        <th>담당 관리자</th>
        <td>
          <select class="form-control" :value="value.admin_id" @input="update" ref="admin_id">
            <option value="">담당 관리자를 선택하세요.</option>
            <template v-for="(account, index) in accounts">
              <option :value="account.id" :key="`admin_id_${user.id}_${index}`">{{ account.userid }}</option>
            </template>
          </select>
        </td>
      </tr>
      <tr>
        <th>인증여부</th>
        <td>
          <select class="form-control" :value="value.certified" @input="update" ref="certified">
            <option value="">인증여부를 선택하세요.</option>
            <option value="confirm">인증완료</option>
            <option value="reject">미인증</option>
          </select>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import {mapState} from 'vuex';

  export default {
    props: ['user', 'value'],
    data() {
      return {
        accountId: '',
        confirmed: 'false'
      };
    },
    computed: {
      ...mapState('account', {
        accounts: state => state.accounts
      })
    },
    methods: {
      update() {
        this.$emit('input', {
          admin_id: this.$refs.admin_id.value,
          certified: this.$refs.certified.value
        });
      },
      click() {
        this.$emit('toggle-certified', {
          userId: this.user.id,
          accountId: this.accountId,
          certified: ! this.user.certified
        });
      }
    }
  }
</script>
