import {DispatchContext, Job} from "@/core/types";
import {CreateBasePropertyData, createBaseProperty, getProperties} from "@/core/api/base-property";

export default class CreateBasePropertyJob implements Job {
  private readonly data: CreateBasePropertyData;

  constructor(data: CreateBasePropertyData) {
    this.data = data;
  }

  async run(context: DispatchContext): Promise<any> {
    await createBaseProperty(this.data);

    const response = await getProperties({
      page: context.store.state.baseProperty[this.data.type].page,
      type: this.data.type
    });

    if (this.data.type === 'wage') {
      context.store.commit('baseProperty/setWageProperties', response.data.data);
    } else {
      context.store.commit('baseProperty/setExcludeProperties', response.data.data);
    }
  }
}
