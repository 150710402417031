// @ts-ignore
import SetUserFromTokenJob from "@/core/jobs/auth/SetUserFromTokenJob";
import {dispatch} from "@/core/dispatcher";

// @ts-ignore
window.$ = window.jQuery = require('jquery');

//require('./plugins/summernote-0.8.12-dist/dist/summernote.js');
//require('./plugins/summernote-0.8.12-dist/dist/lang/summernote-ko-KR');
require('./plugins/summernote-0.8.15/dist/summernote.js');
require('./plugins/summernote-0.8.15/dist/lang/summernote-ko-KR.js');
// require('./plugins/summernote-0.8.14/dist/lang/summernote-ko-KR.js');
require('bootstrap3/dist/js/bootstrap');

if (process.env.NODE_ENV === 'production' && location.protocol != 'https:') {
  location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
}

export default async function() {
}
