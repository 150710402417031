import {DispatchContext, Job} from "@/core/types";
import {User} from '@/core/types/model';

export default class SelectUserJob implements Job {
  private readonly userId: number;

  constructor(userId: number) {
    this.userId = userId;

  }

  async run(context: DispatchContext): Promise<any> {
    const users = context.store.state.user.users.filter((user: User) => user.id === this.userId);

    if (users.length > 0) {
      context.store.commit('user/setSelectedUser', users[0]);
    }
  }
}
